import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Button,
  Typography,
  CircularProgress,
  Box,
  Switch,
  Divider
} from '@mui/material';
import SubscriptionService from 'services/subscriptionService';
import domainData from 'store/domainStore';
import { getMessage } from 'helpers/helper';
import Modal from 'components/common/customModal';
import { useModal } from 'hooks/common';
import M from 'messages';

import TaskAltIcon from '@mui/icons-material/TaskAlt';

import classes from './styles';

const getCurrentPeriod = (currentPlan, subscription) => {
  let period;
  if (currentPlan?.planId === subscription.id) {
    period = subscription.subscriptionPlanPrices.find(priceData => priceData.id === currentPlan.priceId)?.period;
  }
  return period === 'yearly'
}

const Subscription = ({ subscription, currentPlan = null, getProfile, isSuperAdmin = false }) => {
  const [loading, setLoading] = useState(false);
  const [isYearly, setIsYearly] = useState(getCurrentPeriod(currentPlan, subscription));
  const [monthlyPrice, setMonthlyPrice] = useState(null);
  const [yearlyPrice, setYearlyPrice] = useState(null);
  const [isOpenModal, openModal, closeModal] = useModal(false);
  const [isOpenFreePlanModal, openFreePlanModal, closeFreePlanModal] = useModal(false);
  const isCurrentPlan = currentPlan?.planId === subscription.id;
  const isCurrentPrice = subscription.isFree || !currentPlan?.priceId || subscription.subscriptionPlanPrices.find(priceData => priceData.id === currentPlan?.priceId)?.period === (isYearly ? 'yearly' : 'monthly');

  const getPrice = (period) => subscription.subscriptionPlanPrices.find(priceData => priceData.period === period)?.price || 0;

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = () => {
    setIsYearly(!isYearly)
  };

  const handleSubscribeButton = async () => {
    try {
      setLoading(true);
      const payload = {
        planId: subscription.id,
        type: subscription.type
      }
      if (!subscription.isFree) {
        if (subscription.type === 'eu') {
          payload.priceId = yearlyPrice.id
        } else {
          payload.priceId = isYearly ? yearlyPrice.id : monthlyPrice.id;
        }
      }
      const response = await SubscriptionService.subscribe(payload);
      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        await getProfile(subscription.type);
        await domainData.checkSubscriptionAccess();
        enqueueSnackbar(getMessage(response.data), { variant: 'success' });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
  };

  useEffect(() => {
    subscription.subscriptionPlanPrices.forEach(priceData => {
      if (priceData.period === 'monthly') {
        setMonthlyPrice(priceData);
      } else if (priceData.period === 'yearly') {
        setYearlyPrice(priceData);
      }
    })
  }, [subscription.subscriptionPlanPrices]);

  return (
    <>
      <Modal
        open={isOpenModal}
        handleSubmit={handleSubscribeButton}
        handleClose={() => closeModal()}
        disableSave={loading}
        submitLoading={loading}
        title={M.get('subscriptions.modal.title')}
        leftBtnText={M.get('actions.cancel')}
        rightBtnText={M.get('actions.confirm')}
        dialogHeader="changeSubscriptionTitle"
      >
        <Box pb={3}>
          {M.get('subscriptions.modal.description')}{' '}
          <a
            href="https://sharevino.co/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
          >
            {M.get('signUp.terms')}
          </a>
          {' '}{M.get('signUp.and')}{' '}
          <a
            href="https://sharevino.co/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {M.get('signUp.privacy')}
          </a>
          .
        </Box>
      </Modal>
      <Modal
        open={isOpenFreePlanModal}
        handleSubmit={handleSubscribeButton}
        handleClose={() => closeFreePlanModal()}
        disableSave={loading}
        submitLoading={loading}
        title={M.get('subscriptions.freePlanModal.title')}
        leftBtnText={M.get('actions.cancel')}
        rightBtnText={M.get('actions.confirm')}
        dialogHeader="changeSubscriptionTitle"
      >
        <Box pb={3}>
          {M.get('subscriptions.freePlanModal.description')}{' '}
          <a
            href="https://sharevino.co/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
          >
            {M.get('signUp.terms')}
          </a>
          {' '}{M.get('signUp.and')}{' '}
          <a
            href="https://sharevino.co/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {M.get('signUp.privacy')}
          </a>
          .
        </Box>
      </Modal>
      <Card sx={isCurrentPlan && isCurrentPrice ? currentPlan?.status === 'active' ? currentPlan?.cancelAtPeriodEnd ? classes.warningPlan : classes.activePlan : classes.errorPlan : {}}>
        <CardContent sx={classes.planCardContent}>
          <Typography sx={classes.planTitle} gutterBottom>{subscription.name}</Typography>
          <Typography sx={classes.planDesc} >{subscription.description}</Typography>
          <Typography sx={classes.currencyRoot}>
            <Box sx={classes.currency} component='span' >$</Box>
            {
              subscription.name === 'Essential'
                ? <Box component='span'>
                  <Box component='span' sx={{ fontWeight: 'bold' }}>9</Box>
                  <Box component='span' sx={classes.discountedPrice} >{getPrice((subscription.type === 'eu' || isYearly) ? 'yearly' : 'monthly')}</Box>
                </Box>
                : <Box component='span'>{getPrice((subscription.type === 'eu' || isYearly) ? 'yearly' : 'monthly')}</Box>
            }
          </Typography>
          <Typography sx={classes.currencyDesc}>
            {
              subscription.isFree
                ? M.get('subscriptions.freeCurrencyDesc')
                : subscription.type === 'eu' || isYearly
                  ? M.get('subscriptions.billedYearDesc')
                  : M.get('subscriptions.billedDesc')
            }
          </Typography>
          {(subscription.type !== 'eu' && !subscription.isFree) && <Box sx={classes.switch}>
            <Typography>{M.get('subscriptions.billedMonthly')}</Typography>
            <Switch
              checked={isYearly}
              onChange={handleChange}
              disabled={isSuperAdmin}
            />
            <Typography>{M.get('subscriptions.billedYearly')}</Typography>
          </Box>}
          <Button
            variant='contained'
            color='primary'
            sx={{
              ...classes.primaryIconButton,
              "&.Mui-disabled": classes.disabledButton,
              ...classes.subscribeButton,
              ...(subscription.isFree || subscription.type === 'eu' ? classes.buttonMargin : {})
            }}
            disabled={isSuperAdmin || (subscription.isFree && currentPlan?.status === 'active' && currentPlan?.cancelAtPeriodEnd) || (isCurrentPlan && isCurrentPrice) || (!currentPlan?.stripeId && loading)}
            onClick={currentPlan?.stripeId ? openModal : subscription.isFree ? openFreePlanModal : handleSubscribeButton}
          >
            {!currentPlan?.stripeId && loading ? <CircularProgress color="inherit" size={24} /> :
              isCurrentPlan && isCurrentPrice ? M.get('subscriptions.currentPlan') : M.get('subscriptions.subscribe')}
          </Button>
          <Divider sx={classes.divider} />
          <Box sx={classes.textArea}>
            <Typography sx={classes.everythingText} mb={2} >{M.get('subscriptions.everythingIn').replace('{planName}', subscription.name)}</Typography>
            {subscription.type === 'standard'
              ? <>
                <Box sx={classes.text} gap={1}>
                  <TaskAltIcon sx={classes.icon} />
                  <Typography>
                    {subscription.unlimitedWine
                      ? M.get('subscriptions.unlimitedWine')
                      : M.get('subscriptions.wineCount').replace('{wineCount}', subscription.wineCount)
                    }
                  </Typography>
                </Box>
                <Box sx={classes.text} gap={1}>
                  <TaskAltIcon sx={classes.icon} />
                  <Typography>
                    {subscription.unlimitedWineList
                      ? M.get('subscriptions.unlimitedWineList')
                      : subscription.wineListCount > 1 ? M.get('subscriptions.wineListCount').replace('{wineListCount}', subscription.wineListCount) : M.get('subscriptions.singleWineListCount').replace('{wineListCount}', subscription.wineListCount)
                    }
                  </Typography>
                </Box>
                {subscription.dashboardAccess && <Box sx={classes.text} gap={1}>
                  <TaskAltIcon sx={classes.icon} />
                  <Typography>{M.get('subscriptions.dashboardAccess')}</Typography>
                </Box>}
              </>
              : <Box sx={classes.text} gap={1}>
                <TaskAltIcon sx={classes.icon} />
                <Typography>
                  {subscription.unlimitedLabel
                    ? M.get('subscriptions.unlimitedLabel')
                    : M.get('subscriptions.labelCount').replace('{labelCount}', subscription.labelCount)
                  }
                </Typography>
              </Box>
            }
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

Subscription.propTypes = {
  subscription: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    unlimitedWine: PropTypes.bool.isRequired,
    unlimitedWineList: PropTypes.bool.isRequired,
    unlimitedLabel: PropTypes.bool.isRequired,
    wineCount: PropTypes.number,
    wineListCount: PropTypes.number,
    labelCount: PropTypes.number,
    isFree: PropTypes.bool.isRequired,
    dashboardAccess: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    subscriptionPlanPrices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        period: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  currentPlan: PropTypes.shape({
    planId: PropTypes.number.isRequired,
    priceId: PropTypes.number,
    period: PropTypes.string,
    status: PropTypes.string.isRequired,
    cancelAtPeriodEnd: PropTypes.bool.isRequired,
  }),
  getProfile: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
};

export default Subscription;