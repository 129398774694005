import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { PropTypes } from 'prop-types';
import { useSnackbar } from 'notistack';

import {
  Box,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Typography,
  CircularProgress,
  Divider,
  FormControlLabel,
  Tooltip,
  Switch
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import qs from 'qs';

import { routes } from 'config/index';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import NoAccess from 'components/common/noAccessPage';
import { getMessage } from 'helpers/helper';
import KiosksMenuService from 'services/kiosksMenuService';
import ServiceProvidersService from 'services/serviceProvidersService';
import kiosksMenuStore from 'store/kiosksMenusStore';
import domainData from 'store/domainStore';
import SidebarStore from 'store/ui/sidebarStore';
import formClasses from 'components/common/form/styles';
import tableOrWineNumberOptions from 'constants/table-or-wine-number-options';
import M from 'messages';
import DraggableTable from './menuWines';

import classes from './styles';
import QRSection from './qr';


const KiosksMenuCreateWrapper = observer(({ isEdit, isCreate }) => {
  const currentSP = domainData.getCurrentSP();
  const isUserSuperAdmin = domainData.userIsSuperAdmin();
  const kioskMenuStateMode = isEdit ? 'edit' : isCreate ? 'create' : '';
  kiosksMenuStore.setKioskMenuState(kioskMenuStateMode);
  const wineListAccess = domainData.getSubscriptionAccess('wineList');
  if (!wineListAccess) { return <NoAccess />; }
  return (
    <KiosksMenuCreate
      isEdit={isEdit}
      isCreate={isCreate}
      currentSP={currentSP}
      isUserSuperAdmin={isUserSuperAdmin}
    />
  );
});

const KiosksMenuCreate = observer(({ isEdit = false, isCreate = false, currentSP, isUserSuperAdmin }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [spLisLoading, setSpLisLoading] = useState(false);
  const [currMenuData, setCurrMenuData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const isSidebarOpened = SidebarStore.sidebarState;

  const data = kiosksMenuStore.getStoreKioskMenuForCreate();
  const dataErrors = kiosksMenuStore.getKioskMenuDataErrors();
  const selectedItems = kiosksMenuStore.getSelectedMenuItems();
  const defaultsCount = kiosksMenuStore.getDefaultsCount();

  let readonly = false;
  let disabled = false;
  if (!isUserSuperAdmin && !isCreate && !data.active) {
    disabled = true;
    readonly = true;
  }

  const getKioskMenu = useCallback(
    async () => {
      try {
        setLoading(true);
        const response = await KiosksMenuService.getKioskMenu(id);
        if (response) {
          kiosksMenuStore.setKioskMenuData(response.data);
          setCurrMenuData(response.data);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  const createKioskMenu = useCallback(
    async (newData) => {
      try {
        const response = await KiosksMenuService.createKioskMenu(newData);
        if (response) {
          enqueueSnackbar(getMessage(response.data), { variant: 'success' });
          const baseRoute = location.pathname.replace('/create', '');
          navigate(baseRoute);
        }
      } catch (err) {
        setLoading(false);
        setSubmitLoading(false)
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  const updateKioskMenu = useCallback(
    async (newData) => {
      try {
        const response = await KiosksMenuService.updateKioskMenu(newData, id);
        if (response) {
          enqueueSnackbar(getMessage(response.data), { variant: 'success' });
          navigate(routes.kiosksMenus.path);
        }
      } catch (err) {
        setLoading(false);
        setSubmitLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  const getSpList = useCallback(
    async () => {
      try {
        setSpLisLoading(true);
        if (isUserSuperAdmin) {
          const response = await ServiceProvidersService.getServiceProvidersNames();
          if (response) {
            kiosksMenuStore.setSpList(response.data.data);
          }
        } else {
          const response = await ServiceProvidersService.getServiceProvider(currentSP);
          if (response) {
            kiosksMenuStore.setSpList([response.data]);
          }
        }
        setSpLisLoading(false);
      } catch (err) {
        setSpLisLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  useEffect(() => {
    if (isCreate) {
      setLoading(false);
      getSpList();
    } else {
      getKioskMenu();
    }
    return () => {
      if (location.pathname !== `/menus/${id}`) {
        kiosksMenuStore.clearSpCatalogData();
      }
      kiosksMenuStore.clearKioskMenuDataErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCreate && data.serviceProviderId) {
      kiosksMenuStore.getSpCatalogsData(data.serviceProviderId);
      kiosksMenuStore.getSpDefaultMenusCount(data.serviceProviderId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [+data.serviceProviderId]);

  useEffect(() => {
    if (kiosksMenuStore.spCatalogsDataLoaded) {
      const pageParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (pageParams.query) {
        try {
          kiosksMenuStore.setMenuItemsFromUrl(JSON.parse(pageParams.query))
        } catch {
          location.replace({
            search: ''
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kiosksMenuStore.spCatalogsDataLoaded]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const formHasError = kiosksMenuStore.kioskMenuDataHasError();
    if (!formHasError) {
      try {
        const cleanData = kiosksMenuStore.getKioskMenuData(isEdit);
        if (isEdit) {
          setSubmitLoading(true);
          updateKioskMenu(cleanData);
        } else {
          setSubmitLoading(true);
          createKioskMenu(cleanData);
        }
      } catch (err) {
        setSubmitLoading(false)
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
      return true;
    }
    enqueueSnackbar(M.get('form.errors.notValidForm'), { variant: 'error' });
    return false;
  };

  const isSPDisabled = () => {
    if (!isCreate) {
      return true;
    }
    if (currentSP && currentSP !== 'unset' && currentSP !== 'allSP') {
      return true;
    }
    return false;
  };

  const isMenuItemsDisabled = () => !data.serviceProviderId;

  const handleDiscard = () => {
    navigate(routes.kiosksMenus.path);
  }

  if (loading || !domainData.isInitialDataLoaded() || (!isCreate && !kiosksMenuStore.isSpCatalogsDataLoaded() && !kiosksMenuStore.isSpDefaultMenusCountLoaded()) || !selectedItems) return <Loading />;

  return <>
    <Box
      sx={{
        ...classes.page,
        ...(isSidebarOpened && classes.rootDrawerOpen),
        ...(!isSidebarOpened && classes.rootDrawerClose)
      }}
    >
      <PageHeader
        generalPageTitle={`${M.get('kiosksMenu.kioskMenus')} | ${isCreate ? M.get('actions.create') : currMenuData?.menuName || ''}`}
        onlyGeneralPageTitle
      />
      {/* Content */}
      <Box sx={classes.pageContent}>
        <form noValidate>
          <Box component={Paper} sx={classes.boxContainer} pb={2} px={3} pt={3}>
            <Grid container spacing={3}>
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    type="text"
                    label={M.get('kiosksMenu.menuTitle')}
                    id="menuName"
                    name="menuName"
                    value={data.menuName}
                    disabled={disabled}
                    required
                    onChange={kiosksMenuStore.onChangeField}
                    variant="outlined"
                    error={!!dataErrors.menuName}
                    inputProps={{ maxLength: 255 }}
                    helperText=
                    {
                      dataErrors.menuName ?
                        M.get('form.errors.required') :
                        <Box component='span' sx={classes.helperDiv}>
                          <Box component='span'>{M.get('kiosksMenu.menuItemHelper')}</Box>
                          <Box component='span'>{data?.menuName?.length || 0}/{kiosksMenuStore.getMaxMenuNameSymbols()}</Box>
                        </Box>
                    }
                  />
                </FormControl>
              </Grid>
              {isUserSuperAdmin ? <Grid size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth variant="outlined" sx={classes.selectHeight} >
                  <InputLabel id="sp-select-label" sx={dataErrors.serviceProviderId && formClasses.invalidSelect}>
                    {`${M.get('kiosksMenu.serviceProvider')} *`}
                  </InputLabel>
                  <Select
                    id="sp-select"
                    name="serviceProviderId"
                    labelId="sp-select-label"
                    label={`${M.get('kiosksMenu.serviceProvider')} *`}
                    disabled={disabled || isSPDisabled()}
                    value={data.serviceProviderId ?? ''}
                    defaultValue={data.serviceProviderId}
                    onChange={kiosksMenuStore.onChangeField}
                    required
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    sx={{
                      ...classes.select,
                      ...(dataErrors.serviceProviderId && classes.invalidSelectContainer),
                    }}
                    MenuProps={{
                      classes: { paper: classes.menuPaper },
                      anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    }}
                  >
                    {
                      spLisLoading ?
                        <MenuItem value={data.serviceProviderId} disabled>
                          {M.get('kiosksMenu.loading')}
                        </MenuItem>
                        : isCreate
                          ? kiosksMenuStore.getSpList?.map((opt) => (
                            <MenuItem key={opt.id || opt.value} value={opt.id || opt.value}>
                              {opt.name}
                            </MenuItem>
                          ))
                          : <MenuItem value={data.serviceProviderId}>
                            {data.serviceProvider?.name}
                          </MenuItem>
                    }
                  </Select>
                  <FormHelperText sx={classes.errorHelperText}> {dataErrors.serviceProviderId && M.get('form.errors.required')} </FormHelperText>
                </FormControl>
              </Grid> : <></>}
            </Grid>
            {!data.isDefault && defaultsCount > 0 &&
              <Grid mt={3} size={{ xs: 12, sm: 6 }}>
                <FormControl >
                  <FormControlLabel
                    sx={classes.checkboxLabel}
                    disabled={disabled}
                    control={
                      <Switch
                        name='shouldResetWineRating'
                        checked={!!data.shouldResetWineRating}
                        onChange={kiosksMenuStore.onChangeCheckBox}
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label={<Typography sx={classes.fieldHeaderText} >{M.get('kiosksMenu.shouldResetWineRating')} <Tooltip title={M.get('kiosksMenu.shouldResetWineRatingTooltip')}>
                      <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                    </Tooltip></Typography>}
                  />
                </FormControl>
              </Grid>
            }
            <Divider sx={classes.divider} />
            <Grid mt={2} sx={classes.superVino} >
              <FormControl >
                <FormControlLabel
                  sx={classes.checkboxLabel}
                  disabled={disabled}
                  control={
                    <Switch
                      name='useTableOrWineNumber'
                      checked={data.useTableOrWineNumber}
                      onChange={(e) => kiosksMenuStore.onChangeField({ target: { value: e.target.checked, name: 'useTableOrWineNumber' } })}
                      color="primary"
                      disabled={disabled}
                    />
                  }
                  labelPlacement="start"
                  label={
                    <Typography sx={classes.fieldHeaderText} >
                      {M.get('menuItem.useTableOrWineNumber')}{' '}
                      <Tooltip title={M.get('kiosksMenu.useTableOrWineTooltip')}>
                        <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                      </Tooltip>
                    </Typography>
                  }
                />
              </FormControl>
            </Grid>
            {data.useTableOrWineNumber && <>
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, sm: 6 }} >
                  <FormControl fullWidth sx={classes.formControl} >
                    <InputLabel id="is-table-or-wine-label">
                      {M.get('menuItem.setLocation')}
                    </InputLabel>
                    <Select
                      labelId="is-table-or-wine-label"
                      id="is-table-or-wine"
                      name="isTableOrWine"
                      value={data.isTableOrWine}
                      onChange={kiosksMenuStore.onChangeField}
                      disabled={disabled}
                      label={M.get('menuItem.setLocation')}
                    >
                      {tableOrWineNumberOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>{option.title}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormControl fullWidth variant="outlined" sx={classes.formControl} >
                    <TextField
                      id="tableOrWineNumber"
                      name="tableOrWineNumber"
                      value={data.tableOrWineNumber || ''}
                      onChange={kiosksMenuStore.onChangeField}
                      disabled={disabled}
                      label={M.get('menuItem.setNumber')}
                      type="text"
                      slotProps={{
                        htmlInput: { maxLength: 14 }
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </>}
            {isEdit && <QRSection menuID={id} data={data} dataErrors={dataErrors} isUserSuperAdmin={isUserSuperAdmin} disabled={disabled} />}
            <Divider sx={classes.divider} />
            <Grid container spacing={2} sx={classes.menuItemsArea} >
              <Grid size={{ xs: 12, sm: 6 }}>
                <Box sx={{ ...classes.fieldHeader }}>
                  <Typography sx={classes.fieldHeaderText}>
                    {M.get('kiosksMenu.items')}{' '}
                    <Tooltip title={M.get('kiosksMenu.winesTooltip')} placement='right'>
                      <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                    </Tooltip>
                  </Typography>
                </Box>
                <FormControl fullWidth variant="outlined" >
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={kiosksMenuStore.getCatalogsDataForSp()}
                    onChange={kiosksMenuStore.onChangeItemsField}
                    getOptionLabel={(option) => option.name}
                    value={kiosksMenuStore.getSelectedMenuItems()}
                    getOptionDisabled={(option) => !option.active || kiosksMenuStore.getSelectedMenuItems().findIndex(item => item.id === option.id) !== -1}
                    disabled={disabled || isMenuItemsDisabled()}
                    noOptionsText={kiosksMenuStore.isSpCatalogsDataLoaded() ? M.get('kiosksMenu.noOptions') : M.get('kiosksMenu.loading')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={M.get('kiosksMenu.itemsPlaceholder')}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Box sx={classes.tableRoot}>
                  <Grid container sx={classes.container}>
                    <Box sx={classes.tabsContainer}>
                      <DraggableTable selectedItems={selectedItems} />
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box >
      {/* Footer */}
      < Box sx={classes.pageFooter} >
        <Box>
          {!readonly && (
            <Grid container sx={classes.footerPart}>
              <Button
                variant="text"
                color="primary"
                disabled={disabled}
                onClick={handleDiscard}
              >
                {M.get('actions.discard')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={onSubmit}
                disabled={submitLoading || disabled}
              >
                {submitLoading ? <CircularProgress size={16} /> : M.get('form.save')}
              </Button>
            </Grid>
          )}
        </Box>
      </Box >
    </Box >
  </>;
})

KiosksMenuCreate.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
};

export default KiosksMenuCreateWrapper;
