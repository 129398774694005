import { variables } from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    minHeight: `calc(100vh - ${variables.headerHeight} - ${variables.footerHeight} - 40px)`,
    width: '100%',
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.mainBackground,
    color: theme.palette.primary.main,
    flexGrow: 1,
    display: 'block',
    textAlign: 'start',
    padding: '24px',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal',
    overflow: 'hidden',
  },
  cardContent: {
    display: 'block',
    textAlign: 'start',
    '&:last-child': {
      padding: '24px',
    }
  },
  avatar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  avatarIcon: {
    width: '84px',
    height: '84px',
    marginBottom: '12px'
  },
  accountDetails: {
    width: '55%',
  },
  rootDrawerOpen: {
    maxWidth: `calc(100% - ${variables.drawerWidth} - 64px)`
  },
  rootDrawerClose: {
    maxWidth: `calc(100% - ${variables.closedDrawerWidth} - 64px)`
  },
  detailsContainer: {
    marginTop: '25px',
  },
  fields: {
    marginBottom: '25px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.primary.sideBarTextColor,
  },
  fieldName: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary.sideBarTextColor,
  },
  fieldValue: {
    wordWrap: 'break-word',
    width: '100%',
    '& > div > input': {
      color: theme.palette.primary.sideBarTextColor,
      '&:disabled': { opacity: 0.6 },
    },
  },
}