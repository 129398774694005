import { routes } from '../config';

export default {
  welcome: {
    access: ['superAdmin', 'partner']
  },
  dashboardMenu: {
    access: ['superAdmin', 'partner']
  },
  catalog: {
    access: ['superAdmin', 'partner']
  },
  kiosksMenus: {
    access: ['superAdmin', 'partner']
  },
  wineLabels: {
    access: ['superAdmin', 'partner']
  },
  partner: {
    access: ['superAdmin', 'partner']
  },
  users: {
    access: ['superAdmin']
  },
  subscriptions: {
    access: ['partner']
  },
  wineLovers: {
    access: ['superAdmin']
  }
};

const getFirstPageRouteByRole = (currentUser) => {
  const firstOpenedPages = {
    superAdmin: routes.welcome.path,
    partner: routes.welcome.path,
  };
  return currentUser?.usersRole?.name ? firstOpenedPages[currentUser.usersRole.name] : routes.login.path;
}

export { getFirstPageRouteByRole };