import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Card,
  CardContent,
  Box,
  Button,
  Avatar,
  Divider,
  Typography,
  Tabs,
  Tab,
  Alert
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid2';
import domainData from 'store/domainStore';
import userProfileStore from 'store/userProfileStore';
import usersStore from 'store/usersStore';
import SidebarStore from 'store/ui/sidebarStore';
import UsersService from 'services/usersService';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import ProfileImageInput from 'components/common/form/profileImageInput';
import { routes } from 'config/index';
import { getMessage } from 'helpers/helper';
import Subscription from 'pages/subscriptions/subscription';
import M from 'messages';

import AccountDetailsForm from './config/components';
import classes from './styles';

const Profile = observer(({ isProfile }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = domainData.getCurrentUser();
  const [loading, setLoading] = useState(true);
  const [disableSave, setDisableSave] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [planType, setPlanType] = useState('standard');
  const [currentPlan, setCurrentPlan] = useState(null);
  const [activePlans, setActivePlans] = useState([]);

  const isSidebarOpened = SidebarStore.sidebarState;

  const { enqueueSnackbar } = useSnackbar();
  const data = userProfileStore.getUserProfileData();
  const editedData = userProfileStore.getUserProfileEditedData();

  usersStore.getUsersRolesData();

  if (location.pathname.includes('/profile') && +id !== +currentUser.id) {
    navigate(routes.notFound.path);
  }

  const getProfile = useCallback(
    async () => {
      try {
        setLoading(true);
        const response = await UsersService.getUser(id);

        if (response && Object.keys(response.data).length) {
          userProfileStore.setUserProfileData({ ...response.data, usersRolesId: response.data.usersRole.id });
          setInitialData({ ...response.data, usersRolesId: response.data.usersRole.id });
          setActivePlans(response.data.subscriptions.map(subscription => {
            if (subscription.subscriptionPlan.type === planType) {
              setCurrentPlan(subscription);
            }
            return subscription;
          }));
          setLoading(false);
        }
      } catch (err) {
        if (err.message === 'Request failed with status code 403') {
          navigate(routes.notFound.path);
        }
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
          variant: 'error',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, id]
  );

  useEffect(() => {
    if (currentUser.id) {
      getProfile();
    }
    return () => {
      if (currentUser.id) {
        userProfileStore.reinitializeUserProfileData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProfile, currentUser.id, id]);

  const updateProfile = async (newData) => {
    try {
      const response = await UsersService.updateUser({ ...newData }, id);
      if (isProfile && +id === +currentUser.id) {
        const { id: currUserId, phone, email, name, lastLoginDate, serviceProviderId, image } = response.data.user;
        const currentUserUpdatedData = { id: currUserId, phone, email, name, lastLoginDate, serviceProviderId, image };
        domainData.setCurrentUser({ ...currentUser, ...currentUserUpdatedData });
      }
      enqueueSnackbar(getMessage(response.data), { variant: 'success' });
      getProfile();
      setDisableSave(false);
    } catch (err) {
      setDisableSave(false);
      setLoading(false);
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
  }

  const changePassword = async (newData) => {
    try {
      const response = await UsersService.changePassword({ ...newData }, id);
      enqueueSnackbar(getMessage(response.data), { variant: 'success' });
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
  }

  const handleSubmit = () => {
    setDisableSave(true);
    updateProfile(editedData);
  }
  const handleClickCancel = () => {
    if (currentUser.id) {
      userProfileStore.reinitializeUserProfileData(initialData);
    }
  }
  const isFormChanged = () => initialData && data ? JSON.stringify(initialData) !== JSON.stringify(data) : false;
  const dataErrors = userProfileStore.getUserProfileDataErrors();
  const hasError = () => Object.keys(dataErrors).length > 0;
  const changePlanType = (_event, type) => {
    setPlanType(type);
    setCurrentPlan(activePlans.find(plan => plan.subscriptionPlan.type === type));
  };
  const drawSubscription = () =>
    currentPlan && <Grid container spacing={2} mt={3}>
      <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4, xl: 3 }} >
        <Subscription
          subscription={currentPlan.subscriptionPlan}
          currentPlan={currentPlan}
          getProfile={getProfile}
          isSuperAdmin
        />
      </Grid>
    </Grid>;

  if (loading) return <Loading />;
  return (
    <Box
      sx={{
        ...classes.root,
        ...(isSidebarOpened && classes.rootDrawerOpen),
        ...(!isSidebarOpened && classes.rootDrawerClose)
      }}
    >
      <PageHeader
        generalPageTitle={isProfile ? M.get('profile.pageTitle') : `${M.get('account.pageTitle')} | ${initialData.name || ''}`}
        onlyGeneralPageTitle
      />
      <Card sx={classes.card}>
        <CardContent classes={{ root: classes.cardContent }}>
          <Box sx={classes.avatar}>
            {
              isProfile ? (
                <ProfileImageInput
                  onChange={(e) => userProfileStore.onChangeImage(e, 'image')}
                  defaultValue={data.image}
                  userName={data.name.charAt(0)}
                  label={M.get('actions.edit')}
                />
              ) : (
                <Avatar src={data.image} sx={classes.avatarIcon}>
                  {data.name.charAt(0)}
                </Avatar>
              )
            }
          </Box>
          <Box sx={classes.accountDetails} >
            <AccountDetailsForm
              submitChangePassword={changePassword}
              isProfile={isProfile}
              initialProfileData={initialData}
              getProfile={getProfile}
            />
            <Box sx={classes.actionsButtons} mt={4} ml={2} mb={2} >
              <Button
                role="button"
                onClick={handleClickCancel}
                disabled={disableSave || !isFormChanged()}
                sx={classes.cancelButton}
                color="primary"
              >
                {M.get('actions.cancel')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={classes.cancelButton}
                onClick={handleSubmit}
                disabled={disableSave || !isFormChanged() || hasError()}
              >
                {M.get('form.saveChanges')}
              </Button>
            </Box>
          </Box>
          {!isProfile && <Grid container>
            <Grid size={{ xs: 12 }}>
              <Divider />
            </Grid>
            <Grid size={{ xs: 12 }} mt={3} mb={3}>
              <Typography sx={classes.subscriptionTitleText}>
                {M.get('subscriptions.pageTitle')}
              </Typography>
            </Grid>
            <TabContext value={planType}>
              <Tabs
                onChange={changePlanType}
                aria-label="lab API tabs example"
                sx={classes.tabList}
                value={planType}
              >
                <Tab
                  label={M.get('subscriptions.types.standard')}
                  value="standard"
                  sx={classes.primaryIconButton}
                />
                <Tab
                  label={M.get('subscriptions.types.eu')}
                  value="eu"
                  sx={classes.primaryIconButton}
                />
              </Tabs>
              <Box sx={classes.container}>
                {currentPlan
                  ? currentPlan.status === 'active'
                    ? currentPlan.cancelAtPeriodEnd
                      ? <Alert severity="warning">{M.get('subscriptions.statuses.canceled')}</Alert>
                      : <Alert severity="success">{M.get('subscriptions.statuses.active')}</Alert>
                    : <Alert severity="error">{M.get('subscriptions.statuses.error')}</Alert>
                  : <Alert severity="info">{M.get('subscriptions.statuses.noSubscription')}</Alert>
                }
                <TabPanel value="standard">
                  {drawSubscription()}
                </TabPanel>
                <TabPanel value="eu">
                  {drawSubscription()}
                </TabPanel>
              </Box>
            </TabContext>
          </Grid>}
        </CardContent>
      </Card>
    </Box>
  );
});

export default Profile;
