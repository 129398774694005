import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import domainData from 'store/domainStore';
import DateRangeFilter from "./DateRangeFilter";
import EventFilter from "./EventFilter";
import SPFilter from "./SPFilter";
import KPIGlobalSection from "./content/KPIGlobalSection";
import KPISection from "./content/KPISection";
import PieChartSection from "./content/PieChartSection";
import DataTableSection from "./content/DataTableSection";

const DashboardLayout = observer(({ dashboardStore }) => {
  const [menuLoading, setMenuLoaded] = useState(false);
  const isUserSuperAdmin = domainData.userIsSuperAdmin();

  const fetchDashboardData = async () => {
    setMenuLoaded(true);
    dashboardStore.fetchKPIGlobalData();
    dashboardStore.fetchTimeKPIData();
    dashboardStore.fetchVisitsByWineColors();
    dashboardStore.fetchViewsByWineSweetness();
    dashboardStore.fetchViewsByWineStyle();
    dashboardStore.fetchViewsByCountry();
    dashboardStore.fetchAverageRatingByWine();
  }

  const feachInitialData = async () => {
    dashboardStore.setSuperAdmin(isUserSuperAdmin);
    if (isUserSuperAdmin) {
      const spNames = await domainData.getSPListNames();
      dashboardStore.setServiceProviders(spNames);
      dashboardStore.setSelectedSP(spNames[0]);
      dashboardStore.setCurrentSPId(spNames[0].id);
    } else {
      const currentSP = await domainData.getCurrentSP();
      dashboardStore.setCurrentSPId(currentSP);
    }
    await dashboardStore.fechInitialData();
    await fetchDashboardData();
  }

  useEffect(() => {
    // Fetch initial data
    feachInitialData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDateRangeChange = (range) => {
    dashboardStore.setDateRange(range);
    fetchDashboardData(); // Refresh data based on the selected date range
  };

  const onMenuChange = (menu) => {
    dashboardStore.setSelectedMenu(menu);
    fetchDashboardData(); // Refresh data based on the selected event
  }

  const onSPChange = async (sp) => {
    dashboardStore.setSelectedSP(sp);
    dashboardStore.setCurrentSPId(sp.id);
    await dashboardStore.fechInitialData();
    fetchDashboardData(); // Refresh data based on the selected event
  }

  return (
    <Grid>
          <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
            {/* Title on the left */}
            <Grid>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Global Metrics (Unfiltered)</Typography>
            </Grid>

            {/* DateRangeFilter on the right */}
            <Grid container>
              <Grid>
                { isUserSuperAdmin && <SPFilter onSPChange={onSPChange} /> }
                { /* to do add partner filter for Super admin */}
              </Grid>
            </Grid>
          </Grid>
          { menuLoading && dashboardStore.menus.length === 0 ?
            <Typography variant="h6">No data available</Typography>
            :
            <>
              <KPIGlobalSection />
              <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3, mt: 3 }}>
                {/* Title on the left */}
                <Grid>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Time-Based Metrics (Filtered by Date Range and Menus)</Typography>
                </Grid>

                {/* DateRangeFilter on the right */}
                <Grid container>
                  <Grid>
                    { /* to do add partner filter for Super admin */}
                  </Grid>
                  <Grid>
                    { menuLoading && <EventFilter onMenuChange={onMenuChange} /> }
                  </Grid>
                  <Grid>
                    <DateRangeFilter onDateRangeChange={onDateRangeChange} />
                  </Grid>
                </Grid>
              </Grid>
              <KPISection />
              <PieChartSection />
              <DataTableSection />
            </>
          }
    </Grid>
  );
});

export default DashboardLayout;
