import UserName from "../commonCellComponent/userName/UserName";

const tableOptions = {
  wineLover: {
    fields: [
      { id: 'name', label: 'table.wineLover.user', component: UserName, type: 'customComponent', sortable: true, width: '20%', cellPaddingLeft: '24px' },
      { id: 'provider', label: 'table.wineLover.provider', type: 'text', sortable: true, width: '26%' },
      // { id: 'country', label: 'table.wineLover.country', type: 'text', sortable: true, width: '14%' },
      { id: 'lastLogin', label: 'table.wineLover.lastLoginDate', type: 'text', sortable: true, width: '18%', textAlign: 'right', headAlign: 'right', },
      { id: 'lastActivity', label: 'table.wineLover.lastActivity', type: 'text', sortable: true, width: '18%', textAlign: 'right', headAlign: 'right', },
      { id: 'createdAt', label: 'table.wineLover.registrationDate', type: 'text', sortable: true, width: '18%', textAlign: 'right', headAlign: 'right', },
    ],
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    filterFields: [],
    searchFields: ['name', 'email']
  }
};

export default tableOptions;