import { variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    minHeight: `calc(100vh - ${variables.headerHeight} - ${variables.footerHeight} - 40px)`,
    width: '100%',
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.mainBackground,
    flexGrow: 1,
    display: 'block',
    textAlign: 'start',
    padding: '12px 24px 24px 24px',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal',
  },
  container: {
    padding: '24px 24px 16px 24px',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0px 2px 8px rgb(0 0 0 / 5%)'
  },
  buttonMargin: {
    marginTop: '70px',
  },
  primaryIconButton: {
    textTransform: 'none',
    '&.Mui-selected': {
      fontWeight: '600',
    }
  },
  subscribeButton: {
    width: '90%',
    height: '50px',
    minWidth: '109px',
    fontWeight: '500',
    marginTop: '16px',
  },
  divider: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
  },
  activePlan: {
    backgroundColor: 'rgb(237, 247, 237)',
  },
  warningPlan: {
    backgroundColor: 'rgb(255, 244, 229)',
  },
  errorPlan: {
    backgroundColor: 'rgb(253, 240, 239)'
  },
  planCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: '500',
    minHeight: '500px'
  },
  planTitle: {
    fontSize: '30px',
    fontWeight: '600',
  },
  planDesc: {
    textAlign: 'center'
  },
  currencyRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    fontSize: '50px',
    fontWeight: '600',
    color: theme.palette.primary.main
  },
  currency: {
    fontSize: '18px',
    fontWeight: '600',
    margin: '5px 5px 0 0',
  },
  currencyDesc: {
    fontSize: '16px',
  },
  textArea: {
    textAlign: 'center',
  },
  everythingText: {
    fontWeight: '500',
  },
  icon: {
    color: theme.palette.primary.main
  },
  text: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'row',

    '& p': {
      textAlign: 'left'
    }
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '16px',
    
    '& p': {
      fontSize: '12px',
      fontWeight: '500',
    }
  },
  customerPortal: {
    alignContent: 'center',
    textAlign: 'right'
  },
  discountedPrice: {
    position: 'relative',
    color: 'grey',
    marginLeft: '5px',
    fontSize: '40px',
    fontWeight: 400,
    "&::after": {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      borderTop: '2px solid grey; / Reduced thickness /',
      transform: 'translateY(-50%)',
    }
  },
  actionPart: {
    textAlign: 'end'
  },
  plansTitle: {
    fontSize: '40px',
    fontWeight: '600',
    textAlign: 'center',
    color: '#1A5787'
  },
  plansDescription: {
    textAlign: 'center',
    color: '#3D3D3D',
  },
  tabList: {
    width: '100%',
  },
  customerPortalContainer: {
    width: '100%',
    textAlign: 'right',
  },
  subscriptionResponseRoot: {
    width: '100%',
    backgroundColor: '#fff',
  },
  subscriptionResponseIcon: {
    fontSize: '180px',
    color: '#3ABE79'
  },
  subscriptionResponseErrorColor: {
    color: '#DD3834'
  },
  subscriptionResponseSuccessContainer: {
    marginTop: '180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',

    '& img': {
      width: '200px',
      height: '200px',
    }
  },
  subscriptionResponseSuccessText: {
    fontSize: '32px'
  },
  subscriptionResponseButtons: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',

    '& button': {
      textTransform: 'none'
    }
  },
}