import { makeAutoObservable } from "mobx";
import dayjs from "dayjs";
import dashboardService from "services/dashboardService";
import logger from "logger";
import M from 'messages';
import configOptions from "../pages/catalogue/config/config";

class DashboardStore {
  loading = false;

  KPIGlobalDataLoading = true;

  KPIDataLoading = true;

  isSuperAdmin = false;

  menus = [];

  currentSPId = '';

  selectedMenu = {};

  serviceProviders = [];

  selectedSP = {};

  dashboardUrl = '';

  dateRange = { start: dayjs().subtract(6, 'day').format('YYYY-MM-DD'), end: 'today' };

  totalWines = 0;

  totalViews = 0;

  totalRatings = 0;

  averageRating = 0;

  timeTotalRatings = 0;

  timeAverageRating = 0;

  visitsByCountry = [];

  viewsByWine = [];

  averageRatingByWine = [];

  viewsByColor = [];

  viewsBySweetness = [];

  viewsByStyle = [];

  viewsByCountry = [];

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentSPId = (id) => {
    this.currentSPId = id;
  }

  setSuperAdmin = (isSuperAdmin) => {
    this.isSuperAdmin = isSuperAdmin;
  }

  setServiceProviders = (serviceProviders) => {
    this.serviceProviders = serviceProviders;
  }

  setSelectedSP = (sp) => {
    this.selectedSP = sp;
  }

  fechInitialData = async () => {
    try {
      const { data } = await dashboardService.getMenus(this.currentSPId);
      this.menus = data;
      [this.selectedMenu] = data.filter((item) => item.isDefault);
    } catch (error) {
      logger.error("Failed to fetch initial data", error);
    }
  };

  setSelectedMenu = (menu) => {
    this.selectedMenu = menu;
  }

  fetchKPIGlobalData = async () => {
    try {
      // Fetch data from API (replace with actual API call)
      const { data } = await dashboardService.getKPIData(this.currentSPId);

      // For now, using hardcoded values
      this.totalWines = data.totalWines; // Update with `data.totalWines`
      this.totalRatings = data.totalRatings; // Update with `data.totalRatings`
      this.averageRating = data.averageRating; // Update with `data.averageRating`
      this.KPIGlobalDataLoading = false;
    } catch (error) {
      this.KPIGlobalDataLoading = false;
      logger.error("Failed to fetch KPI data", error);
    }
  };

  // Placeholder function for API call to fetch KPI data
  fetchTimeKPIData = async () => {
    try {
      // Fetch data from API (replace with actual API call)
      const { data } = await dashboardService.dashboardTimeKPIs(this.dateRange.start, this.dateRange.end, this.selectedMenu.id, this.currentSPId);

      this.totalViews = data.totalViews; // Update with `data.totalViews`
      this.timeTotalRatings = data.totalRatings; // Update with `data.totalRatings`
      this.timeAverageRating = data.averageRating; // Update with `data.averageRating`
      this.viewsByWine = data.viewsByWine; // Update with `data.viewsByWine`
      this.KPIDataLoading = false;
    } catch (error) {
      this.KPIDataLoading = false;
      logger.error("Failed to fetch KPI data", error);
    }
  };

  // Placeholder function for API call to fetch Visits by Country data
  fetchVisitsByWineColors = async () => {
    try {
      // Replace with actual API call
      const response = await dashboardService.getViewsByColor(this.dateRange.start, this.dateRange.end, this.selectedMenu.id, this.currentSPId);
      const data = response.data.viewsByColor;
      for (let i = 0; i < data.length; i += 1) {
        data[i].name = M.get(configOptions.colorGroup.fields.filter((item) => item.value === data[i].name)[0].label);
      }
      this.viewsByColor = data;
    } catch (error) {
      logger.error("Failed to fetch visits by country data", error);
    }
  };

  // Placeholder function for API call to fetch Average Rating by Wine data
  fetchAverageRatingByWine = async () => {
    try {
      const response = await dashboardService.getAverageRatingByWine(this.dateRange.start, this.dateRange.end, this.selectedMenu.id, this.currentSPId);
      this.averageRatingByWine = response.data.averageRatingByWine;
    } catch (error) {
      logger.error("Failed to fetch average rating by wine data", error);
    }
  };

  // Placeholder function for API call to fetch Views by Sweetness data
  fetchViewsByWineSweetness = async () => {
    try {
      // Replace with actual API call
      const response = await dashboardService.getViewsBySweetness(this.dateRange.start, this.dateRange.end, this.selectedMenu.id, this.currentSPId);
      const data = response.data.viewsBySweetness;
      for (let i = 0; i < data.length; i += 1) {
        data[i].name = M.get(configOptions.sweetnessGroup.fields.filter((item) => item.value === data[i].name)[0].label);
      }
      this.viewsBySweetness = data;
    } catch (error) {
      logger.error("Failed to fetch views by sweetness data", error);
    }
  };

  // Placeholder function for API call to fetch Views by Style data
  fetchViewsByWineStyle = async () => {
    try {
      // Replace with actual API call
      const response = await dashboardService.getViewsByStyle(this.dateRange.start, this.dateRange.end, this.selectedMenu.id, this.currentSPId);
      const data = response.data.viewsByStyle;
      for (let i = 0; i < data.length; i += 1) {
        data[i].name = M.get(configOptions.styleGroup.fields.filter((item) => item.value === data[i].name)[0].label);
      }
      this.viewsByStyle = data;
    } catch (error) {
      logger.error("Failed to fetch views by style data", error);
    }
  };

  // Placeholder function for API call to fetch Views by Style data
  fetchViewsByCountry = async () => {
    try {
      // Replace with actual API call
      const response = await dashboardService.getViewsByCountry(this.dateRange.start, this.dateRange.end, this.selectedMenu.id, this.currentSPId);
      this.viewsByCountry = response.data.viewsByCountry
    } catch (error) {
      logger.error("Failed to fetch views by style data", error);
    }
  };

  setDateRange = (newDateRange) => {
    this.dateRange = newDateRange;
  }

  setSelectedEvent = (event) => {
    this.selectedEvent = event;
  }

  setDashboardUrl = (url) => {
    this.dashboardUrl = url;
  }
}

const dashboardStore = new DashboardStore();
export default dashboardStore;
