import { get } from 'services/client';
import { apiEndpoints } from 'config';

const dashboardService = {
  getMenus: async (id) => {
    const options = { url: apiEndpoints.dashboardMenus.replace(':id', id) };
    return get(options);
  },
  getKPIData: async (id) => {
    const options = {
      url: apiEndpoints.dashboardKPIs.replace(':id', id)
    };
    return get(options);
  },

  dashboardTimeKPIs: async (startDate, endDate, menuId, id) => {
    const options = {
      url: apiEndpoints.dashboardTimeKPIs.replace(':id', id),
      params: { startDate, endDate, menuId }
    };
    return get(options);
  },

  getViewsByColor: async (startDate, endDate, menuId, id) => {
    const options = {
      url: apiEndpoints.dashboardViewsByColor.replace(':id', id),
      params: { startDate, endDate, menuId }
    };
    return get(options);
  },
  getViewsBySweetness: async (startDate, endDate, menuId, id) => {
    const options = {
      url: apiEndpoints.dashboardViewsBySweetness.replace(':id', id),
      params: { startDate, endDate, menuId }
    };
    return get(options);
  },
  getViewsByStyle: async (startDate, endDate, menuId, id) => {
    const options = {
      url: apiEndpoints.dashboardViewsByStyle.replace(':id', id),
      params: { startDate, endDate, menuId }
    };
    return get(options);
  },

  getAverageRatingByWine: async (startDate, endDate, menuId, id) => {
    const options = {
      url: apiEndpoints.dashboardAverageRatingByWine.replace(':id', id),
      params: { startDate, endDate, menuId }
    };
    return get(options);
  },

  getViewsByCountry: async (startDate, endDate, menuId, id) => {
    const options = {
      url: apiEndpoints.dashboardViewsByCountry.replace(':id', id),
      params: { startDate, endDate, menuId }
    };
    return get(options);
  }
};

export default dashboardService;
