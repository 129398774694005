import M from 'messages';

export default [
  {
    value: 'area',
    title: M.get('menuItem.tableOrWineNumberOptions.area')
  },
  {
    value: 'booth',
    title: M.get('menuItem.tableOrWineNumberOptions.booth')
  },
  {
    value: 'hall',
    title: M.get('menuItem.tableOrWineNumberOptions.hall')
  },
  {
    value: 'table',
    title: M.get('menuItem.tableOrWineNumberOptions.table')
  },
  {
    value: 'tent',
    title: M.get('menuItem.tableOrWineNumberOptions.tent')
  },
  {
    value: 'wine',
    title: M.get('menuItem.tableOrWineNumberOptions.wine')
  },
  {
    value: 'zone',
    title: M.get('menuItem.tableOrWineNumberOptions.zone')
  }
];