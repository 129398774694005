import { get } from 'services/client';
import { apiEndpoints } from 'config';

export default {
  getWineLovers: async (params) => {
    const options = { url: apiEndpoints.wineLovers, params: {...params} };
    return get(options);
  },
  getWineLover: async (id) => {
    const options = { url: apiEndpoints.wineLover.replace(':id', id)};
    return get(options);
  },
}