import React, { useCallback, useState } from 'react';
import { Popover, MenuItem, Button, Typography, Box } from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import dashboardStore from 'store/dashboardStore';

const SPFilter = ({ onSPChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRangeSelect = (menu) => {
    onSPChange(menu);
    closePopover();
  };

  return (
    <Box>
      <Button
        onClick={openPopover}
        endIcon={<ArrowDropDownIcon />}
        sx={{ textTransform: 'none', display: 'flex', alignItems: 'center', height: '36px' }}
      >
        <Typography variant="body2" sx={{ mr: 0 }}>
        Partners List <b>{dashboardStore.serviceProviders.find((sp) => sp.id === dashboardStore.selectedSP.id)?.name}</b>
        </Typography>
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box>
          {dashboardStore.serviceProviders.map((sp) => (
            <MenuItem key={sp.id} onClick={() => handleRangeSelect(sp)}>
              {sp.name}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

SPFilter.propTypes = {
  onSPChange: PropTypes.func.isRequired
};

export default SPFilter;
