import React from 'react';
import { Card, CardContent, Typography, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { observer } from 'mobx-react';
import dashboardStore from 'store/dashboardStore';

const KPISection = observer(() => (
  <Grid container spacing={2} sx={{ mt: 3 }}>
    <Grid size={{ xs: 12, md: 4 }}>
      <Card>
        <CardContent>
          <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>Total Views</Typography>
          {dashboardStore.KPIDataLoading ? (
            <CircularProgress />
          ) : (
            <Typography variant="h4">{dashboardStore.totalViews}</Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
    <Grid size={{ xs: 12, md: 4 }}>
      <Card>
        <CardContent>
          <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>Average Rating</Typography>
          {dashboardStore.KPIDataLoading ? (
            <CircularProgress />
          ) : (
            <Typography variant="h4">{Math.round(dashboardStore.timeAverageRating * 100) / 100}</Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
    <Grid size={{ xs: 12, md: 4 }}>
      <Card>
        <CardContent>
          <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>Total Ratings</Typography>
          {dashboardStore.KPIDataLoading ? (
            <CircularProgress />
          ) : (
            <Typography variant="h4">
              {dashboardStore.timeTotalRatings}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
  </Grid>
));

export default KPISection;
