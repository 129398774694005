import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Card,
  CardContent,
  Box,
  Avatar,
  Divider,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import SidebarStore from 'store/ui/sidebarStore';
import WineLoversService from 'services/wineLoversService';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import { getMessage } from 'helpers/helper';
import { adaptWineLoverDetailsData } from 'helpers/adapter';
import M from 'messages';

import classes from './styles';

const WineLover = observer(() => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [wineLoverData, setWineLoverData] = useState({});
  const isSidebarOpened = SidebarStore.sidebarState;

  const getWineLoverData = useCallback(
    async () => {
      try {
        setLoading(true);
        const response = await WineLoversService.getWineLover(id);
        setWineLoverData(adaptWineLoverDetailsData(response.data));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
          variant: 'error',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, id]
  );

  useEffect(() => {
    getWineLoverData();
  }, [getWineLoverData]);


  if (loading) return <Loading />;

  return (
    <Box
      sx={{
        ...classes.root,
        ...(isSidebarOpened && classes.rootDrawerOpen),
        ...(!isSidebarOpened && classes.rootDrawerClose)
      }}
    >
      <PageHeader
        generalPageTitle={`${M.get('wineLovers.pageTitle')} | ${wineLoverData.name || ''}`}
        onlyGeneralPageTitle
      />
      <Card sx={classes.card}>
        <CardContent classes={{ root: classes.cardContent }}>
          <Box sx={classes.avatar}>
            <Avatar src={wineLoverData.image} sx={classes.avatarIcon}>
              {wineLoverData.name.charAt(0)}
            </Avatar>
          </Box>
          <Box sx={classes.accountDetails} >
            <Grid container sx={classes.detailsContainer}>
              <Grid size={{ xs: 12 }} sx={classes.fields}>
                <Grid size={{ xs: 5 }}>
                  <Typography sx={classes.fieldName}>
                    {M.get('wineLovers.details.name')}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 7 }}>
                  <Grid container spacing={1}>
                    <Grid size={{ xs: 10 }}>
                      <Typography sx={classes.fieldValue}>
                        {wineLoverData.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              </Grid>
              <Grid size={{ xs: 12 }} sx={classes.fields}>
                <Grid size={{ xs: 5 }}>
                  <Typography sx={classes.fieldName}>
                    {M.get('wineLovers.details.email')}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 7 }}>
                  <Grid container spacing={1}>
                    <Grid size={{ xs: 10 }}>
                      <Typography sx={classes.fieldValue}>
                        {wineLoverData.email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              </Grid>
              <Grid size={{ xs: 12 }} sx={classes.fields}>
                <Grid size={{ xs: 5 }}>
                  <Typography sx={classes.fieldName}>
                    {M.get('wineLovers.details.provider')}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 7 }}>
                  <Grid container spacing={1}>
                    <Grid size={{ xs: 10 }}>
                      <Typography sx={classes.fieldValue}>
                        {wineLoverData.provider}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              </Grid>
              {/* <Grid size={{ xs: 12 }} sx={classes.fields}>
                <Grid size={{ xs: 5 }}>
                  <Typography sx={classes.fieldName}>
                    {M.get('wineLovers.details.country')}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 7 }}>
                  <Grid container spacing={1}>
                    <Grid size={{ xs: 10 }}>
                      <Typography sx={classes.fieldValue}>
                        {wineLoverData.country}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              </Grid> */}
              <Grid size={{ xs: 12 }} sx={classes.fields}>
                <Grid size={{ xs: 5 }}>
                  <Typography sx={classes.fieldName}>
                    {M.get('wineLovers.details.lastLoginDate')}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 7 }}>
                  <Grid container spacing={1}>
                    <Grid size={{ xs: 10 }}>
                      <Typography sx={classes.fieldValue}>
                        {wineLoverData.lastLogin}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              </Grid>
              <Grid size={{ xs: 12 }} sx={classes.fields}>
                <Grid size={{ xs: 5 }}>
                  <Typography sx={classes.fieldName}>
                    {M.get('wineLovers.details.lastActivity')}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 7 }}>
                  <Grid container spacing={1}>
                    <Grid size={{ xs: 10 }}>
                      <Typography sx={classes.fieldValue}>
                        {wineLoverData.lastActivity}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              </Grid>
              <Grid size={{ xs: 12 }} sx={classes.fields}>
                <Grid size={{ xs: 5 }}>
                  <Typography sx={classes.fieldName}>
                    {M.get('wineLovers.details.registrationDate')}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 7 }}>
                  <Grid container spacing={1}>
                    <Grid size={{ xs: 10 }}>
                      <Typography sx={classes.fieldValue}>
                        {wineLoverData.createdAt}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});

export default WineLover;
