import { makeAutoObservable, action, computed, toJS, extendObservable, set } from "mobx";
import SettingsService from 'services/settingsService';
import { deleteAllSpacesFromStr, stringTrimValidation } from 'helpers/helper';
import { isEmail, isValidPhoneNumber, isValidUrl } from "helpers/validatorHelper";
import { isSuperAdmin } from "helpers/usersRolesHelper";
import domainData from 'store/domainStore';

const currentUser = domainData.getCurrentUser();
const isUserSuperAdmin = isSuperAdmin(currentUser);

const defaultData = {
  name: '', 
  description: '', 
  type: 'winery', 
  website: '', 
  reservation: '',
  region: null, 
  countryId: null, 
  logo: '', 
  fullAddress: '',
  contactPersonName: '', 
  contactPersonEmail: '', 
  contactPersonPhone: '', 
  partnerFeatureTags: []
};

const requiredFields = [
  "name", "description", "type", "region", "countryId", 'fullAddress', "logo"
];

if (isUserSuperAdmin) {
  requiredFields.push('accountStatus');
  defaultData.sortingOrder = '';
  defaultData.accountStatus = 'active';
}

const createRequiredFields = requiredFields.concat(['contactPersonName', 'contactPersonEmail']);

class ServiceProvidersStore {

  getDefaultStoreProps = () => ({
    serviceProviders: {
      count: 0,
      data: []
    },
    filterData: {
      params: {
        sort: {
          field: "created_at",
          order: "desc"
        },
        filter: {},
        limit: 50,
        skip: 0
      }
    },
    spData: JSON.parse(JSON.stringify(defaultData)),
    spDataErrors: {},
    isCreateFormChanged: false,
    countries: [],
    countriesLoaded: false,
    countriesLoading: false,
    countriesSelected: null,
    makeDataRefresh: false
  });

  constructor() {
    makeAutoObservable(this);
    extendObservable(this, { ...this.getDefaultStoreProps()});
  }

  @action
  reset() {
    set(this, this.getDefaultStoreProps());
  }

  @action
  setStoreServiceProviders(newData) {
    this.serviceProviders.data = [];
    newData.data.forEach(this.addStoreServiceProviders);
    this.serviceProviders.count = newData.count;
  }

  @action
  addStoreServiceProviders = (element) => {
    this.serviceProviders.data.push(element);
  }

  @action
  setFilter(filter) {
    this.filterData = filter;
  }

  @computed
  get filter() {
    const filterToJS = toJS(this.filterData)
    return filterToJS;
  }

  @action
  initializeSpData = () => {
    this.spData = JSON.parse(JSON.stringify(defaultData));
  }

  getStoreSpForCreate = () => this.spData;

  @action
  createFormChanged = (value = true) => {
    this.isCreateFormChanged = value;
  }

  @computed
  getCreateFormChanged = () => this.isCreateFormChanged;

  @action
  onChangeCheckBox = (e) => {
    const { checked, name } = e.target;
    this.spData[name] = checked;
  }

  @action
  onChangeField = (e, isCreate) => {
    const { name, value } = e.target;
    if (name === 'countryId') {
      this.spData.region = null;
    }
    this.spData[name] = value;

    let reqFields = requiredFields;
    if (isCreate) {
      reqFields = createRequiredFields;
    }
    
    if (!value && reqFields.includes(name)) {
      this.spDataErrors[name] = 'required';
    } else {
      delete this.spDataErrors[name];
    }
    return true;
  }

  @action
  onChangePhoneField = (value, isCreate) => {
    this.spData.contactPersonPhone = value;
    let reqFields = requiredFields;
    if (isCreate) {
      reqFields = createRequiredFields;
    }

    // Check if only the country code exists
    const digitsOnly = value.replace(/\D/g, '');

    // Get the country code length from the phone number input
    const countryCodeMatch = value.match(/^\+\d+/);
    const countryCodeLength = countryCodeMatch ? countryCodeMatch[0].length - 1 : 0;

    if ((!value || digitsOnly.length <= countryCodeLength) && reqFields.includes('contactPersonPhone')) {
      this.spDataErrors.contactPersonPhone = 'required';
    } else if (value && !isValidPhoneNumber(value)) {
      this.spDataErrors.contactPersonPhone = 'invalidPhone';
    } else {
      delete this.spDataErrors.contactPersonPhone;
    }
    return true;
  }

  @action
  onChangeEmailField = (e, isCreate) => {
      const { name, value } = e.target;
      const newValue = deleteAllSpacesFromStr(value);
      this.spData[name] = newValue;
      let reqFields = requiredFields;
      if (isCreate) {
        reqFields = createRequiredFields;
      }
      if (!newValue && reqFields.includes(name)) {
          this.spDataErrors[name] = 'required';
      } else if (newValue && !isEmail(newValue)) {
          this.spDataErrors[name] = 'invalidEmail';
      } else {
          delete this.spDataErrors[name];
      }
      return true;
  }
  
  @action
  onChangeUrlField = (e, isCreate) => {
    this.createFormChanged();
    const { name, value } = e.target;
    this.spData[name] = value;
    let reqFields = requiredFields;
    if (isCreate) {
      reqFields = createRequiredFields;
    }
    if (!value && reqFields.includes(name)) {
      this.spDataErrors[name] = 'required';
    } else if (value && !isValidUrl(value)) {
      this.spDataErrors[name] = 'invalidUrl';
    } else {
      delete this.spDataErrors[name];
    }
    return true;
  }

  getSpData = (isEdit) => {

    const data = { ...this.spData };
    if (isUserSuperAdmin) {
      data.sortingOrder = this.spData.sortingOrder ?? ''
    }
    if (isEdit) {
      delete data.id;
      delete data.created_at;
    }
    return data;
  };

  @action
  setSpData = (data) => {
    this.spData = data;
  }

  getAdaptedData = (data) => {
    const adaptedData = { ...data };
  
    if (isUserSuperAdmin) {
      adaptedData.sortingOrder = data.sortingOrder ??  '';
    }

    return adaptedData;
  };

  getSpDataErrors = () => this.spDataErrors;

  @action
  clearSpDataErrors = () => {
    this.spDataErrors = {};
  }

  spDataHasError = (isCreate) => {
    let hasError = false;
    let reqFields = requiredFields;    
    if (isCreate) {
      reqFields = createRequiredFields;
    }
    reqFields.forEach(i => {
      if (!this.spData[i]) {
        this.spDataErrors[i] = 'required';
        hasError = true;
      } else if (this.spDataErrors[i]) {
        hasError = true;
      } else {
        this.spDataErrors[i] = '';
      }
    });

    if (this.spDataErrors.website || this.spDataErrors.reservation) {
      hasError = true;
    }
    return hasError;
  };

  // countries data
  
  getCountriesData = async () => {
    if (this.countriesLoaded) {
      return this.countries;
    }
    if (this.countriesLoading) {
      return new Promise(resolve => {
        const checkData =() => {
          if (this.countriesLoaded) {
            resolve(this.countries)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.loadCountriesData();
    return this.countries;
  }

  loadCountriesData = async () => {
    this.countriesLoading = true;
    try {
      const response = await SettingsService.getCountriesOptions();
      if (response) {
        this.countries = response.data;
        this.countriesLoaded = true;
        this.countriesLoading = false;
      }
    } catch (err) {
      this.countriesLoading = false;
    }
  }

  @computed
  get isCountryOptionLoaded() {
    return this.countriesLoaded;
  }

  clearCountriesData = () => {
    this.countriesLoaded = false;
    this.countriesLoading = false;
  }

  @computed
  get countriesOptions() {
    const countriesOptions = this.countries?.map(i => ({
      id: i.id,
      name: i.name
    }));
    
    return countriesOptions;
  }

  @computed
  get selectedCountry() {
    this.countriesSelected = this.countries.find(country => country.id === this.spData.countryId);
    return this.countriesSelected || '';
  }

  @action
  addNewAutocompleteDataTmp = (text, name, addType) => {
    this.isSpChanged = false;
    if (!text) return;
    const existItem = this.spData[addType].find(i => i.name === text);
    if (!existItem) {
      const newCategory = { id: 'newId', name: text };
      this[`${addType}Selected`] = newCategory;
      this.spData[addType] = [...this.spData[addType], newCategory];
      delete this.spDataErrors[name];
    }
  };

  onChangeAutocomplete = (newValue, name, addType) => {
    this.createFormChanged();
    const value = newValue?.inputValue || newValue;
    if (typeof value === 'string' && value && stringTrimValidation(value)) {
      this.addNewAutocompleteDataTmp(value, name, addType);
    } else if (!value && requiredFields.includes(name)) {
        this.spDataErrors[name] = 'required';
        this.spData[name] = null;
        this[`${addType}Selected`] = null;
    } else {
      this[`${addType}Selected`] = value;
      this.spData[name] = value?.id;
      delete this.spDataErrors[name];
    }
  }

  @action
  setMakeDataRefresh = () => {
    this.makeDataRefresh = !this.makeDataRefresh;
  }

  getMakeDataRefresh = () => this.makeDataRefresh;

  getTags = () => this.spData.partnerFeatureTags;

  setTags = (tags) => {
    // Filter out duplicate items based on the 'name' property
    const uniqueTags = tags.reduce((acc, tag) => {
      if (!acc.some(existingTag => existingTag.name.toLowerCase() === tag.name.toLowerCase())) {
        acc.push(tag);
      }
      return acc;
    }, []);
  
    this.spData.partnerFeatureTags = uniqueTags;
  }

}

export default new ServiceProvidersStore();