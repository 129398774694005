import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box, Button, Tooltip, DialogContentText } from '@mui/material';
import Grid from '@mui/material/Grid2';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import qs from 'qs';

import M from 'messages';
import CustomTable from 'components/common/customTableV2';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import Modal from 'components/common/modal';
import EmptyData from 'components/common/emptyData';
import NoAccess from 'components/common/noAccessPage';
import WineLabelsService from 'services/wineLabelsService';
import wineLabelsStore from 'store/wineLabelStore';
import SidebarStore from 'store/ui/sidebarStore';
import domainData from 'store/domainStore';
import wineLableQRCodeStore from 'store/wineLableQRCodeStore';
import { useModal } from 'hooks/common';
import { adaptWineLabelsTableData } from 'helpers/adapter';
import { replacePath, getMessage } from 'helpers/helper';
import QRCodeModal from './create/QRCodeModal';
import CustomTableToolbar from './toolbar';
import SetInMarketModal from './modals/setInMarketModal';

import configOptions from './config/config';

import classes from './styles';

const WineLabelsWrapper = observer(({ path }) => {
  const labelAccess = domainData.getSubscriptionAccess('label');
  if (!labelAccess) { return <NoAccess type='eu' />; }
  return <WineLabels path={path} />;
}); 
const WineLabels = observer(({ path }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();
  const pageParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [wineLabelsData, setWineLabelsData] = useState({});
  const [fromFilter, setFromFilter] = useState(true);
  const [dataLoading, setDataLoading] = useState({ loading: true, loaded: false });
  const [selectedRows, setSelectedRows] = useState([]);
  const [moveLoading, setMoveLoading] = useState(false);
  const [isOpenMove, openModalMove, closeModalMove] = useModal(false);
  const [isOpenInMarket, openModalInMarket, closeModalInMarket] = useModal(false);
  const [isSingleRowInMarket, setIsSingleRowInMarket] = useState(false);
  const [inMarketRowId, setInMarketRowId] = useState('');
  const [isOpenQR, openModalQR, closeModalQR] = useModal(false);
  const isUserSuperAdmin = domainData.userIsSuperAdmin();
  const isSidebarOpened = SidebarStore.sidebarState;
  // NOTE: cut / symbol
  const pageItem = path.substring(1);

  const filteredParamsStore = wineLabelsStore.filter;
  const { searchFields, filterFields } = configOptions;

  const setFilter = (filter) => {
    replacePath(navigate, filter);
    wineLabelsStore.setFilter(filter);
  };

  const getCurrentServiceProvider = () => {
    const currentSP = domainData.getCurrentSP();
    const currSP = currentSP && currentSP !== 'allSP' ? { currentServiceProvider: currentSP } : {};
    return currSP;
  }

  const queryParams = {
    params: {
      sort: pageParams?.sort || filteredParamsStore.params.sort,
      filter: pageParams?.filter || filteredParamsStore.params.filter,
      search: pageParams?.search || filteredParamsStore.params.search,
      limit: pageParams?.limit || filteredParamsStore.params.limit,
      skip: pageParams?.skip || filteredParamsStore.params.skip,
    }
  }

  const getWineLabelsLists = useCallback(
    async (params, withFilter = true) => {
      try {
        setDataLoading(prevState => ({ ...prevState, loaded: false }));
        setFromFilter(withFilter);
        const urlParams = params.params;
        setFromFilter(!!((urlParams?.filter && Object.keys(urlParams.filter).length) || urlParams?.search));
        replacePath(navigate, params);
        wineLabelsStore.setFilter(params);
        const newParams = { params: { ...params.params, ...getCurrentServiceProvider() } };
        const response = await WineLabelsService.getWineLabels(newParams);
        if (response) {
          wineLabelsStore.setStoreWineLabels(response.data);
          setWineLabelsData({
            count: response.data.count,
            data: adaptWineLabelsTableData(response.data.data),
            createAbility: response.data.createAbility
          });
        }
      } catch (err) {
        // TODO: handle error case
        enqueueSnackbar('Get wine labels lists error', { variant: 'error' });
      } finally {
        setDataLoading({ loading: false, loaded: true });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  useEffect(() => {
    getWineLabelsLists(queryParams);
    setFilter(queryParams);
    setSelectedRows([]);
    return () => {
      wineLabelsStore.setFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWineLabelsLists]);

  const handleCreateWineLabel = () => {
    navigate(`${location.pathname}/create`);
  };

  const onSearchCallback = (value) => {
    let oldValue = queryParams.params?.search?.value;
    if (oldValue === undefined) {
      oldValue = '';
    }
    if (searchFields && (oldValue !== value)) {
      const newFilter = { params: { ...queryParams.params, search: { ...queryParams.params.search, value, fields: searchFields }, skip: 0 } }
      if (!value) {
        delete newFilter.params.search;
      }
      setFilter(newFilter);
      getWineLabelsLists(newFilter);
    }
  };

  const onFilterCallback = (filterObj) => {
    if (filterObj && !Array.isArray(filterObj)) {
      const fieldKeyList = Object.keys(filterObj);
      let newFilter = {};
      fieldKeyList.forEach(i => {
        const fieldValue = filterObj[i];
        let oldValue = queryParams.params?.filter?.[i];
        if (oldValue === undefined) {
          oldValue = '';
        }
        if (filterFields && (oldValue !== fieldValue) && fieldValue !== undefined) {
          newFilter = { ...newFilter, params: { ...queryParams.params, ...newFilter.params, filter: { ...queryParams.params.filter, ...newFilter?.params?.filter, [i]: fieldValue }, skip: 0 } }
        }
      });
      if (Object.keys(newFilter).length) {
        setFilter(newFilter);
        getWineLabelsLists(newFilter);
      }
    }
  };

  const handleDeleteLabels = async () => {
    try {
      setMoveLoading(true);
      const response = await WineLabelsService.deleteWineLabelMany({ ids: selectedRows });
      if (response) {
        enqueueSnackbar(getMessage(response.data), { variant: 'success' });
      }
      await getWineLabelsLists(queryParams, false);
      setMoveLoading(false);
      setSelectedRows([]);
    } catch (err) {
      setMoveLoading(false);
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
    closeModalMove()
  };

  const handleClickDeleteIcon = async () => {
    setMoveLoading(false);
    openModalMove();
  };

  const handleSetInMarket = async () => {
    try {
      setMoveLoading(true);
      const payload = { ids: isSingleRowInMarket ? [inMarketRowId] : selectedRows };
      const response = await WineLabelsService.wineLabelSetInMarket(payload);
      if (response) {
        enqueueSnackbar(getMessage(response.data), { variant: 'success' });
      }
      await getWineLabelsLists(queryParams, false);
      setMoveLoading(false);
      setSelectedRows([]);
    } catch (err) {
      setMoveLoading(false);
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
    closeModalInMarket()
  };

  const handleClickSetInMarket = async () => {
    setMoveLoading(false);
    setIsSingleRowInMarket(false);
    openModalInMarket();
  };

  const getShowInMarket = () => {
    let status = false;
    if (wineLabelsData && wineLabelsData.data) {
      const selectedRowsData = wineLabelsData.data.filter(row => selectedRows.includes(row.id));
      const showSetInMarket = selectedRowsData.every(row => row.active && row.status === 'no');
      status = showSetInMarket;
    }
    return status;
  }
  const showSetInMarket = getShowInMarket();

  const toolbarView = (
    <CustomTableToolbar
      onSearchCallback={onSearchCallback}
      filteredParams={queryParams}
      numSelected={selectedRows.length}
      filterFields={filterFields}
      onFilterCallback={onFilterCallback}
      handleDelete={handleClickDeleteIcon}
      setInMarket={handleClickSetInMarket}
      showSetInMarket={showSetInMarket}
    />
  );

  const isEmptyState = () => (!fromFilter && !wineLabelsData?.data?.length);

  const openPath = (row) => `${location.pathname}/${row.id}`;

  if (dataLoading.loading) { return (<Loading />); }

  const createButton = (disabled) => (
    <Button
      onClick={handleCreateWineLabel}
      startIcon={<AddIcon sx={classes.primaryIcon} />}
      sx={classes.primaryIconButton}
      variant="contained"
      color="primary"
      disabled={disabled}
    >
      {M.get('actions.create')}
    </Button>
  );

  const handleShowQA = async (id) => {
    await wineLableQRCodeStore.loadData(id);
    openModalQR();
  }

  const handleSave = async () => {
    try {
      await wineLableQRCodeStore.updateQRCode();
    } catch (err) {
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
    closeModalQR();
  };

  const handleShowInMarket = async (id) => {
    setIsSingleRowInMarket(true);
    setInMarketRowId(id);
    openModalInMarket();
  }

  const handleCloneAction = async (row) => {
    try {
      await WineLabelsService.cloneLabel({
        serviceProviderId: row.serviceProviderId,
      }, row.id);
      enqueueSnackbar('Wine label cloned successfully', { variant: 'success' });
      await getWineLabelsLists(queryParams, false);
    } catch (error) {
      enqueueSnackbar(getMessage(error?.response?.data, 'error'), { variant: 'error' });
    }
  };

  return (
    <Box
      sx={{
        ...classes.root,
        ...(isSidebarOpened && classes.rootDrawerOpen),
        ...(!isSidebarOpened && classes.rootDrawerClose)
      }}
    >
      <QRCodeModal
        open={isOpenQR}
        handleClose={closeModalQR}
        handleSave={handleSave}
        isCreate="false"
        isEdit="true"
      />
      <Modal
        open={isOpenMove}
        leftBtnText={M.get('actions.cancel')}
        rightBtnText={M.get('actions.delete')}
        handleClose={closeModalMove}
        handleSubmit={handleDeleteLabels}
        title={M.get('catalog.deleteConfirmationTitle')}
        submitLoading={moveLoading}
        disableSave={moveLoading}
      >
        <DialogContentText className={classes.moveToModalView}>
          {M.get('catalog.deleteConfirmationText')}
        </DialogContentText>
      </Modal>
      <SetInMarketModal
        open={isOpenInMarket}
        handleClose={closeModalInMarket}
        handleSubmit={handleSetInMarket}
        classes={classes}
      />
      <PageHeader
        generalPageTitle={M.get('wineLabels.pageTitle')}
        element={(<Grid sx={classes.actionPart} size={{ xs: 12 }}>
          {(!isUserSuperAdmin && !wineLabelsData.createAbility
            ? <Tooltip title={M.get('wineLabels.createLimit')} placement="top">
              <span>
                {createButton(true)}
              </span>
            </Tooltip>
            : createButton()
          )}
        </Grid>
        )}
      />
      <Grid size={{ xs: 12 }}>
        <Box mt={3} sx={classes.tableRoot}>
          {isEmptyState() && dataLoading.loaded ? (
            <EmptyData
              title={pageItem}
              titleClassName="emptyPageTitle"
              withCreate={false}
            />
          ) : (
            <Grid container sx={classes.container}>
              <Box sx={classes.tabsContainer}>
                <CustomTable
                  tableSources={wineLabelsData}
                  tableOptions={configOptions}
                  loading={dataLoading.loading}
                  filteredParams={queryParams}
                  getDataOnChange={getWineLabelsLists}
                  setFilteredParams={setFilter}
                  setSelectedRows={setSelectedRows}
                  selectedRows={selectedRows}
                  openPath={openPath}
                  withOpenAction={false}
                  withEditAction
                  handleShowQA={handleShowQA}
                  withQRAction
                  withInMarketAction
                  handleShowInMarket={handleShowInMarket}
                  toolbarView={toolbarView}
                  disableCondition={(row) => !row.active || !row.partnerIsActive}
                  rowIsNotSelectable
                  withCloneAction
                  inMarketActionCondition={(row) => row.active && row.status === 'no'}
                  QRActionCondition={(row) => row.active}
                  handleCloneAction={handleCloneAction}
                />
              </Box>
            </Grid>
          )}
        </Box>
      </Grid>
    </Box>
  )
});

WineLabels.propTypes = {
  path: PropTypes.string.isRequired
}

export default WineLabelsWrapper;