import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import {
  Box,
  Paper,
  Button,
  Divider,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Typography,
  Autocomplete,
  Switch,
  Tooltip,
  InputAdornment,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  getMessage,
} from 'helpers/helper';

import { routes } from 'config/index';
import CataloguesServices from 'services/cataloguesService';
import ServiceProvidersService from 'services/serviceProvidersService';
import domainData from 'store/domainStore';
import cataloguesStore from 'store/catalogueStore';

import ImageInput from 'components/common/form/imageInput';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import ButtonsGroup from 'components/common/customBtnGroup';
import NoAccess from 'components/common/noAccessPage';
import tableOrWineNumberOptions from 'constants/table-or-wine-number-options';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import M from 'messages';
import formClasses from 'components/common/form/styles';
import classes from './styles';
import PriceUnits from './priceUnits';
import Ratings from './ratings';
import WineSpecs from './wineSpecs';
import configOptions from '../config/config';

import SPECIALS from '../../../constants/specials';
import PAIRING from '../../../constants/pairing';

const maxSymbols = 255;

const CreateMenuItemWrapper = observer(({ isEdit, isCreate }) => {
  const isUserSuperAdmin = domainData.userIsSuperAdmin();
  const currentSPId = domainData.getCurrentSP();
  const menuItemMode = isEdit ? 'edit' : isCreate ? 'create' : '';
  cataloguesStore.setMenuItemState(menuItemMode);
  const wineAccess = domainData.getSubscriptionAccess('wine');
  if (!wineAccess) { return <NoAccess />; }

  return (
    <CreateMenuItem
      isEdit={isEdit}
      isCreate={isCreate}
      currentSPId={currentSPId}
      isUserSuperAdmin={isUserSuperAdmin}
    />
  );
});

const CreateMenuItem = observer(({ isEdit = false, isCreate = false, currentSPId, isUserSuperAdmin }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [spLisLoading, setSpLisLoading] = useState(false);
  const [currMenuItemData, setCurrMenuItemData] = useState(null);
  const [disableSave, setDisableSave] = useState(false);
  const data = cataloguesStore.getStoreMenuItemForCreate();
  const dataErrors = cataloguesStore.getMenuItemDataErrors();
  const spList = cataloguesStore.getSpList;
  let readonly = false;
  let disabled = false;
  if (!isUserSuperAdmin && !isCreate && !data.active) {
    disabled = true;
    readonly = true;
  }

  const setReadyData = (readyData) => {
    cataloguesStore.setMenuItemData(readyData, readyData.serviceProvider);
    setCurrMenuItemData(readyData);
    setLoading(false);
  }

  const getCatalog = useCallback(
    async () => {
      try {
        setLoading(true);
        const response = await CataloguesServices.getCatalog(id);
        if (response) {
          setReadyData(response.data);
        }
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, id]
  );

  const actionSucceeded = (response) => {
    enqueueSnackbar(getMessage(response.data), { variant: 'success' });
    setDisableSave(false);
  }

  const createCatalog = useCallback(
    async (newData) => {
      try {
        setDisableSave(true);
        const response = await CataloguesServices.createCatalog(newData);
        if (response) {
          actionSucceeded(response);
          const baseRoute = location.pathname.replace('/create', '');
          navigate(baseRoute);
        }
      } catch (err) {
        setDisableSave(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  const updateCatalog = useCallback(
    async (newData) => {
      try {
        setDisableSave(true);
        const response = await CataloguesServices.updateCatalog(newData, id);
        if (response) {
          actionSucceeded(response);
          navigate(routes.catalogue.path);
        }
      } catch (err) {
        setDisableSave(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  const getSpList = useCallback(
    async () => {
      try {
        setSpLisLoading(true);
        if (isUserSuperAdmin) {
          const response = await ServiceProvidersService.getServiceProvidersNames();
          if (response) {
            cataloguesStore.setSpList(response.data.data);
          }
        } else {
          const response = await ServiceProvidersService.getServiceProvider(currentSPId);
          if (response) {
            cataloguesStore.setSpList([response.data]);
            cataloguesStore.setProducerDefaultValue(response.data.name);
          }
        }
        setSpLisLoading(false);
      } catch (err) {
        setSpLisLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  useEffect(() => {
    if (isCreate) {
      cataloguesStore.setMenuItemState('create')
      cataloguesStore.setServiceProviderId(currentSPId);
      setLoading(false);
      getSpList();
    } else if (id) {
      cataloguesStore.clearMenuItemDataErrors();
      getCatalog(id);
    }
    cataloguesStore.getCountriesData();
    cataloguesStore.getRatingsData();
    cataloguesStore.getWineTypesData();
    return () => {
      cataloguesStore.clearTypeAutocompleteData();
      cataloguesStore.reset();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSPDisabled = () => {
    if (!isCreate) {
      return true;
    }
    return currentSPId && currentSPId !== 'unset' && currentSPId !== 'allSP';
  };

  const handleDiscard = () => {
    navigate(routes.catalogue.path);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const formHasError = cataloguesStore.menuItemDataHasError();
    if (!formHasError) {
      try {
        const formData = cataloguesStore.getMenuItemData(isEdit);
        if (isEdit) {
          updateCatalog(formData);
        } else {
          createCatalog(formData);
        }
      } catch (err) {
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
      return true;
    }
    enqueueSnackbar(M.get('form.errors.notValidForm'), { variant: 'error' });
    return false;
  };


  const isFormChanged = () => {
    const cloneMenuItemData = JSON.parse(JSON.stringify(currMenuItemData));
    const dataStored = JSON.parse(JSON.stringify(data));
    const condition = cloneMenuItemData && dataStored ? JSON.stringify(cloneMenuItemData) !== JSON.stringify(dataStored) : false
    return condition || cataloguesStore.getCreateFormChanged();
  }

  const isSaveButtonDisabled = () => (isCreate ?
    (disabled || !cataloguesStore.getCreateFormChanged() || disableSave) :
    (disabled || !isFormChanged() || disableSave)
  );

  const getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name;
  }
  const isOptionEqualToValue = (option, value) => option.id === value.id;

  const years = Array.from({ length: 61 }, (_, index) => (2030 - index).toString());
  const yearOptions = ['NV', ...years,];

  if (loading || !domainData.isInitialDataLoaded() || (data.serviceProviderId && !cataloguesStore.getAllOptionsLoaded())) return <Loading />;

  return (
    <Box sx={classes.page}>
      <PageHeader
        generalPageTitle={`${M.get('catalog.pageTitle')} | ${isCreate ? M.get('actions.create') : currMenuItemData?.name || ''}`}
        onlyGeneralPageTitle
      />
      {/* Content */}
      <Box sx={classes.pageContent}>
        <form onSubmit={onSubmit} noValidate>
          <Box component={Paper} sx={classes.boxContainer} pb={2} px={3} pt={3}>
            <Typography sx={classes.secondaryTitle}>{M.get('menuItem.productDetails')}</Typography>
            <Grid container spacing={3} mb={3} mt={1}>
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    label={`${M.get('menuItem.name')}`}
                    id="name"
                    name="name"
                    value={data.name}
                    disabled={disabled}
                    required
                    onChange={cataloguesStore.onChangeField}
                    variant="outlined"
                    error={!!dataErrors.name}
                    inputProps={{ maxLength: maxSymbols }}
                    helperText={
                      dataErrors.name ?
                        M.get('form.errors.required') :
                        <Box component='span' sx={classes.helperDiv}>
                          <Box component='span'>{data.name?.length || 0}/{maxSymbols}</Box>
                        </Box>
                    }
                  />
                </FormControl>
              </Grid>
              {isUserSuperAdmin &&
                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="sp-select-label" sx={{ ...classes.placeholderColor, ...(dataErrors.serviceProviderId && formClasses.invalidSelect) }}>
                      {`${M.get('menuItem.serviceProvider')} *`}
                    </InputLabel>
                    <Select
                      id="sp-select"
                      variant="outlined"
                      name="serviceProviderId"
                      labelId='sp-select-label'
                      label={M.get('menuItem.serviceProvider')}
                      required
                      disabled={disabled || isSPDisabled()}
                      value={data.serviceProviderId ?? ''}
                      defaultValue={data.serviceProviderId}
                      error={!!dataErrors.serviceProviderId}
                      onChange={cataloguesStore.onChangeField}
                      MenuProps={{
                        classes: { paper: classes.menuPaper },
                        anchorOrigin: { vertical: "bottom", horizontal: "center" },
                      }}
                    >
                      {
                        spLisLoading ?
                          <MenuItem value={data.serviceProviderId} disabled>
                            {M.get('menuItem.loading')}
                          </MenuItem>
                          : isCreate
                            ? spList?.map((opt) => (
                              <MenuItem key={opt.id || opt.value} value={opt.id || opt.value}>
                                {opt.name}
                              </MenuItem>
                            ))
                            : <MenuItem value={data.serviceProviderId}>
                              {data.serviceProvider?.name}
                            </MenuItem>
                      }
                    </Select>
                    {dataErrors.serviceProviderId && <FormHelperText sx={classes.errorHelperText}> {M.get('form.errors.required')} </FormHelperText>}
                  </FormControl>
                </Grid>
              }
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    label={`${M.get('menuItem.producer')}`}
                    id="producer"
                    name="producer"
                    value={data.producer}
                    disabled={disabled}
                    required
                    onChange={cataloguesStore.onChangeField}
                    variant="outlined"
                    error={!!dataErrors.producer}
                    inputProps={{ maxLength: maxSymbols }}
                    helperText={
                      dataErrors.producer ?
                        M.get('form.errors.required') :
                        <Box component='span' sx={classes.helperDiv}>
                          <Box component='span'>{data.producer?.length || 0}/{maxSymbols}</Box>
                        </Box>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth variant="outlined">
                  <Autocomplete
                    id="typeId"
                    variant="outlined"
                    clearOnBlur
                    selectOnFocus
                    forcePopupIcon
                    handleHomeEndKeys
                    disabled={disabled}
                    value={cataloguesStore.getSelectedWineType}
                    defaultValue={cataloguesStore.getSelectedWineType}
                    onChange={(e, value) => cataloguesStore.onChangeAutocomplete(value, 'typeId', 'menuItemTypes')}
                    isOptionEqualToValue={isOptionEqualToValue}
                    options={cataloguesStore.wineTypesOptions}
                    getOptionLabel={getOptionLabel}
                    getOptionDisabled={(option) => option.isDisabled}
                    renderOption={(props, option) => {
                      const { key, ...rest } = props;
                      return <li key={key} {...rest}>{option.name}</li>;
                    }}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        required
                        label={`${M.get('menuItem.wineType')}`}
                        variant="outlined"
                        error={!!dataErrors.typeId}
                        helperText={!!dataErrors.typeId && M.get('form.errors.required')}
                        inputProps={{ ...props.inputProps, maxLength: maxSymbols }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth variant="outlined">
                  <Autocomplete
                    id="countryId"
                    variant="outlined"
                    clearOnBlur
                    selectOnFocus
                    forcePopupIcon
                    handleHomeEndKeys
                    disabled={disabled}
                    value={cataloguesStore.getSelectedOption}
                    defaultValue={cataloguesStore.getSelectedOption}
                    onChange={(e, value) => cataloguesStore.onChangeAutocomplete(value, 'countryId', 'countries')}
                    options={cataloguesStore.countriesOptions}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    getOptionDisabled={(option) => option.isDisabled}
                    renderOption={(props, option) => {
                      const { key, ...rest } = props;
                      return <li key={key} {...rest}>{option.name}</li>;
                    }}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        required
                        label={`${M.get('menuItem.countries')}`}
                        variant="outlined"
                        error={dataErrors.countryId}
                        helperText={!!dataErrors.countryId && M.get('form.errors.required')}
                        inputProps={{ ...props.inputProps, maxLength: maxSymbols }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="year-select-label">{M.get('menuItem.wineYear')}</InputLabel>
                  <Select
                    labelId="year"
                    id='year-select'
                    name="year"
                    label={`${M.get('menuItem.wineYear')} *`}
                    value={data.year || ""}
                    onChange={cataloguesStore.onChangeField}
                    error={!!dataErrors.year}
                    MenuProps={{
                      classes: { paper: classes.menuPaper },
                      anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    }}
                  >
                    <MenuItem key="None" value="">
                      None
                    </MenuItem>
                    {yearOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  {dataErrors.year && <FormHelperText sx={classes.errorHelperText}> {M.get('form.errors.required')} </FormHelperText>}
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    required
                    id="region"
                    name="region"
                    variant="outlined"
                    disabled={disabled}
                    value={data.region}
                    onChange={cataloguesStore.onChangeField}
                    label={`${M.get('menuItem.region')}`}
                    error={!!dataErrors.region}
                    inputProps={{ maxLength: maxSymbols }}
                    helperText={
                      dataErrors.region ?
                        M.get('form.errors.required') :
                        <Box component='span' sx={classes.helperDiv}>
                          <Box component='span'>{data.region?.length || 0}/{maxSymbols}</Box>
                        </Box>
                    }
                  />
                </FormControl>
              </Grid >
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="whereToBuy"
                    name="whereToBuy"
                    variant="outlined"
                    disabled={disabled}
                    value={data.whereToBuy || ''}
                    onChange={cataloguesStore.onChangeField}
                    label={M.get('menuItem.whereToBuy')}
                    helperText={
                      <Box component='span' sx={classes.helperDiv}>
                        <Box component='span'>{data.whereToBuy?.length || 0}/{maxSymbols}</Box>
                      </Box>
                    }
                    InputProps={{
                      inputProps: { maxLength: maxSymbols },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip placement="top" title={M.get('menuItem.whereToBuyTooltip')}>
                            <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid >
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="subRegion"
                    name="subRegion"
                    variant="outlined"
                    disabled={disabled}
                    value={data.subRegion}
                    onChange={cataloguesStore.onChangeField}
                    label={M.get('menuItem.subRegion')}
                    inputProps={{ maxLength: maxSymbols }}
                    helperText={
                      <Box component='span' sx={classes.helperDiv}>
                        <Box component='span'>{data.subRegion?.length || 0}/{maxSymbols}</Box>
                      </Box>
                    }
                  />
                </FormControl>
              </Grid >
            </Grid >
            <Grid container>
              <Grid size={{ xs: 12 }}>
                <Divider />
                <Box sx={classes.sectionHeaderText}>
                  <Typography sx={classes.fieldHeaderText} component='h1'>
                    {M.get(configOptions.colorGroup.label)}
                  </Typography>
                </Box>
                <Grid mt={1} mb={2}>
                  <ButtonsGroup disabled={disabled} groupObject={configOptions.colorGroup} handleChange={cataloguesStore.onChangeButtonGroup} value={data.color} />
                </Grid>
                <Divider sx={dataErrors.color && classes.errorHelperText} />
                {dataErrors.color && <FormHelperText sx={classes.errorHelperText}> {M.get('form.errors.required')} </FormHelperText>}
              </Grid>
            </Grid>
            <Grid container>
              <Grid size={{ xs: 12 }}>
                <Box sx={classes.sectionHeaderText}>
                  <Typography sx={classes.fieldHeaderText} component='h1'>
                    {M.get(configOptions.sweetnessGroup.label)}
                  </Typography>
                </Box>
                <Grid mt={1} mb={2}>
                  <ButtonsGroup disabled={disabled} groupObject={configOptions.sweetnessGroup} handleChange={cataloguesStore.onChangeButtonGroup} value={data.sweetness} />
                </Grid>
                <Divider sx={dataErrors.sweetness && classes.errorHelperText} />
                {dataErrors.sweetness && <FormHelperText sx={classes.errorHelperText}> {M.get('form.errors.required')} </FormHelperText>}
              </Grid>
            </Grid>
            <Grid container>
              <Grid size={{ xs: 12 }}>
                <Box sx={classes.sectionHeaderText}>
                  <Typography sx={classes.fieldHeaderText} component='h1'>
                    {M.get(configOptions.styleGroup.label)}
                  </Typography>
                </Box>
                <Grid mt={1} mb={2}>
                  <ButtonsGroup disabled={disabled} groupObject={configOptions.styleGroup} handleChange={cataloguesStore.onChangeButtonGroup} value={data.style} />
                </Grid>
                <Divider sx={dataErrors.style && classes.errorHelperText} />
                {dataErrors.style && <FormHelperText sx={classes.errorHelperText}> {M.get('form.errors.required')} </FormHelperText>}
              </Grid>
            </Grid>
            <Grid>&nbsp;</Grid>
            <Grid container spacing={3} mb={2}>
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="pairing-select-label">{M.get('menuItem.pairing')}</InputLabel>
                  <Select
                    labelId="pairing"
                    id="pairing-select"
                    name="pairing"
                    label={`${M.get('menuItem.pairing')}`}
                    value={data.pairing || ''}
                    defaultValue={data.pairing}
                    onChange={cataloguesStore.onChangeField}
                    disabled={disabled}
                    MenuProps={{
                      classes: { paper: classes.menuPaper },
                      anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    }}
                  >
                    <MenuItem key="None" value="">
                      None
                    </MenuItem>
                    {PAIRING.map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="special-select-label">{M.get('menuItem.special')}</InputLabel>
                  <Select
                    labelId="special"
                    id="special-select"
                    name="special"
                    value={data.special || ""}
                    onChange={cataloguesStore.onChangeField}
                    disabled={disabled}
                    defaultValue={data.special}
                    label={M.get('menuItem.special')}
                    MenuProps={{
                      classes: { paper: classes.menuPaper },
                      anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    }}
                  >
                    <MenuItem key="None" value="">
                      None
                    </MenuItem>
                    {SPECIALS.map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid >
            </Grid >
            <Divider />
            <Grid container mb={2}>
              <Grid size={{ xs: 12 }}>
                <Typography sx={classes.sectionHeaderText}>
                  {M.get('menuItem.wineSpecs')}{' '}
                  <Tooltip title={M.get('menuItem.wineSpecsTooltip')} placement='top'>
                    <HelpOutlineIcon sx={classes.helpIcon} />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <WineSpecs data={data.menuItemSpecs} label={M.get('menuItem.wineSpecView')} disabled={disabled} />
              </Grid>
            </Grid>
            <Divider />
            <Grid container>
              <Grid size={{ xs: 12 }}>
                <Typography component='h1' sx={classes.sectionHeaderText}>{M.get('menuItem.rating')}</Typography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Ratings data={data.ratings} disabled={disabled} />
              </Grid>
            </Grid>
            <Divider />
            <Grid container>
              <Grid size={{ xs: 12 }}>
                <Typography component='h1' sx={classes.sectionHeaderText}>{M.get('menuItem.price')}</Typography>
                <PriceUnits data={data.menuItemPrices} disabled={disabled} />
              </Grid>
            </Grid>
            <Grid container sx={classes.superVino} >
              <Grid size={{ xs: 12 }}>
                <FormControl >
                  <FormControlLabel
                    sx={classes.checkboxLabel}
                    disabled={disabled}
                    control={
                      <Switch
                        name='isSuperVino'
                        checked={data.isSuperVino}
                        onChange={cataloguesStore.onChangeCheckBox}
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <Typography sx={classes.fieldHeaderText}>
                        {M.get('menuItem.superVino')}{' '}
                        <Tooltip title={M.get('menuItem.superVinoTooltip')} placement='top'>
                          <HelpOutlineIcon sx={classes.helpIcon} />
                        </Tooltip>
                      </Typography>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Divider />
            <Grid sx={classes.superVino} >
              <FormControl >
                <FormControlLabel
                  sx={classes.checkboxLabel}
                  disabled={disabled}
                  control={
                    <Switch
                      name='useTableOrWineNumber'
                      checked={data.useTableOrWineNumber}
                      onChange={(e) => cataloguesStore.onChangeField({ target: { value: e.target.checked, name: 'useTableOrWineNumber' } })}
                      color="primary"
                      disabled={disabled}
                    />
                  }
                  labelPlacement="start"
                  label={
                    <Typography sx={classes.fieldHeaderText}>
                      {M.get('menuItem.useTableOrWineNumber')}{' '}
                      <Tooltip title={M.get('menuItem.useTableOrWineNumberTooltip')} placement='top'>
                        <HelpOutlineIcon sx={classes.helpIcon} />
                      </Tooltip>
                    </Typography>
                  }
                />
              </FormControl>
            </Grid>
            {data.useTableOrWineNumber && <>
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormControl fullWidth>
                    <InputLabel id="is-table-or-wine-label">{M.get('menuItem.setLocation')}</InputLabel>
                    <Select
                      labelId="is-table-or-wine-label"
                      id="is-table-or-wine"
                      name="isTableOrWine"
                      value={data.isTableOrWine}
                      label={M.get('menuItem.setLocation')}
                      onChange={cataloguesStore.onChangeField}
                      disabled={disabled}
                    >
                      {tableOrWineNumberOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>{option.title}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      id="tableOrWineNumber"
                      name="tableOrWineNumber"
                      value={data.tableOrWineNumber || ''}
                      onChange={cataloguesStore.onChangeField}
                      disabled={disabled}
                      label={M.get('menuItem.setNumber')}
                      type="text"
                      slotProps={{
                        htmlInput: { maxLength: 14 }
                      }}
                    />
                  </FormControl>
                </Grid >
              </Grid >
            </>
            }
            <Grid container >
              <Grid mt={3} size={{ xs: 12 }} >
                <Box sx={classes.sectionHeaderText} mb={2}>
                  <Typography sx={classes.fieldHeaderText}>
                    {`${M.get('menuItem.itemImage.description')} *`}
                    <Tooltip title={M.get('menuItem.itemImage.tooltip')} placement='top'>
                      <HelpOutlineIcon sx={classes.helpIcon} />
                    </Tooltip>
                  </Typography>
                </Box>
                <FormControl fullWidth variant="filled">
                  <ImageInput
                    onChange={(e) => cataloguesStore.onChangeField({ target: { value: e, name: 'image' } })}
                    defaultValue={data.image}
                    disabled={disabled}
                    setDisableSave={setDisableSave}
                    errorStyle={!!dataErrors.image}
                    type='wine'
                  />
                  {dataErrors.image && <FormHelperText sx={classes.errorHelperText}> {M.get('form.errors.required')} </FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </Box >
        </form >
      </Box >
      {/* Footer */}
      < Box sx={classes.pageFooter} >
        <Box>
          {!readonly && (
            <Grid container sx={classes.footerPart}>
              <Button
                variant="text"
                color="primary"
                onClick={disabled || handleDiscard}
              >
                {M.get('actions.discard')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={disabled || isSaveButtonDisabled()}
                onClick={onSubmit}
              >
                {isCreate ? M.get('form.create') : M.get('form.save')}
              </Button>
            </Grid>
          )}
        </Box>
      </Box >
    </Box >
  );
})

CreateMenuItem.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
};

export default CreateMenuItemWrapper;