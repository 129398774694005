const routes = {
  home: {
    path: '/',
    accessRoutes: ['superAdmin', 'partner'],
  },
  login: {
    path: '/login',
    accessRoutes: ['superAdmin', 'partner'],
  },
  signUp: {
    path: '/signup',
    accessRoutes: ['superAdmin', 'partner'],
  },

  settings: {
    path: '/settings',
    accessRoutes: ['superAdmin'],
  },

  users: {
    path: '/users',
    accessRoutes: ['superAdmin', 'partner'],
  },
  userEdit: {
    path: '/users/:id',
    accessRoutes: ['superAdmin', 'partner'],
  },

  serviceProviders: {
    path: '/serviceProviders',
    accessRoutes: ['superAdmin'],
  },
  serviceProviderCreate: {
    path: '/serviceProviders/create',
    accessRoutes: ['superAdmin'],
  },
  serviceProviderEdit: {
    path: '/serviceProviders/:id',
    accessRoutes: ['superAdmin', 'partner'],
  },

  kiosksMenus: {
    path: '/menus',
    accessRoutes: ['superAdmin', 'partner'],
  },
  kiosksMenusCreate: {
    path: '/menus/create',
    accessRoutes: ['superAdmin', 'partner'],
  },
  kiosksMenusEdit: {
    path: '/menus/:id',
    accessRoutes: ['superAdmin', 'partner'],
  },

  catalogue: {
    path: '/menuItems',
    accessRoutes: ['superAdmin', 'partner'],
  },
  catalogueCreate: {
    path: '/menuItems/create',
    accessRoutes: ['superAdmin', 'partner'],
  },
  catalogueEdit: {
    path: '/menuItems/:id',
    accessRoutes: ['superAdmin', 'partner'],
  },

  wineLabels: {
    path: '/labels',
    accessRoutes: ['superAdmin', 'partner'],
  },
  wineLabelsCreate: {
    path: '/labels/create',
    accessRoutes: ['superAdmin', 'partner'],
  },
  wineLabelsEdit: {
    path: '/labels/:id',
    accessRoutes: ['superAdmin', 'partner'],
  },

  profileEdit: {
    path: '/profile/:id',
    accessRoutes: ['superAdmin', 'partner'],
  },

  dashboard: {
    path: '/dashboard',
    accessRoutes: ['superAdmin', 'partner'],
  },

  notFound: {
    path: '/notFound',
    title: 'pages.notFound',
    accessRoutes: ['superAdmin'],
  },


  forgotPassword: {
    path: '/forgot/password',
    accessRoutes: ['superAdmin'],
  },
  resetPassword: {
    path: '/reset/password',
    accessRoutes: ['superAdmin'],
  },
  inviteUser: {
    path: '/user/invitation',
    accessRoutes: ['superAdmin', 'partner'],
  },

  changePassword: {
    path: '/change/password',
    accessRoutes: ['superAdmin'],
  },
  welcome: {
    path: '/welcome',
    accessRoutes: ['superAdmin', 'partner'],
  },
  contactUs: {
    path: '/login',
    accessRoutes: ['superAdmin'],
  },

  subscriptions: {
    path: '/subscriptions',
    accessRoutes: ['partner'],
  },
  subscription: {
    path: '/subscription/:status/:sessionId',
    accessRoutes: ['partner'],
  },

  wineLovers: {
    path: '/wineLovers',
    accessRoutes: ['superAdmin'],
  },
  wineLover: {
    path: '/wineLovers/:id',
    accessRoutes: ['superAdmin'],
  }
}

export default routes;